import React from 'react';
// import { NavLink } from 'react-router-dom';
import {
    GET_MATERIALTYPE, GET_ONEPOSTS, GET_CATEGORY, GET_MATERIALGRADE, GET_MATERIAL_SHAPE, GET_MANUFACTURING_PROCESS,
    GET_MANUFACTURING_OPERATION, GET_MANUFACTURING_FINISHING_TYPE, GET_MANUFACTURING_FINISHING_COLOR, GET_TESTING
    , GET_SOLUTION_PROVIDER, GET_RESOURCES, GET_GRADEBY_TYPE, GET_TESTING_FORBY_ID, GET_TESTING_OPERATION_ID_TYPE, GET_SOLUTION_PROVIDER_FORBY_ID, GET_SOLUTION_INSD_ID_TYPE
    , GET_RESOURCE_INSD_ID_TYPE, GET_RESOURCE_PROVIDER_FORBY_ID, NEWQUERY, GET_SHAPEBY_TYPE_NEW, GET_DIMENSION_TYPE_NEW
} from './query';
import { Query, Mutation } from 'react-apollo';
import SimpleReactValidator from 'simple-react-validator';
// import request from 'graphql-request';
import DatePicker from "react-datepicker";
import { toast } from 'react-toastify';


class BusinessSubmit extends React.Component {
    constructor(props) {
        super(props)
        this.validator = new SimpleReactValidator();
        this.state = {
            noOfBusiness: JSON.parse(localStorage.businesses).length,
            businessId: JSON.parse(localStorage.businesses)[0],
            categories: [],
            isFalse: false,
            isUploading: false,
            sectionMand: {
                one: true,
                two: true
            },
            materialDimension: {},
            attachments: {
                material_requirement: "",
                manufacturing_requirement: "",
                testing_requirement: "",
                solution_provider_requirement: "",
                resource_requirement: "",
            },
            request: {
                businesses: JSON.parse(localStorage.businesses),
                company_name: "",
                name: "",
                email: "",
                phone: "",
                location: "",
                material_requirement: {
                    deadline_date: new Date(),
                    special_notes: "",
                    logistics_support: false,
                    material_test_report: false,
                    attachment: "",
                    material_requests: [{
                        quantity: null,
                        material_type: null,
                        material_grade: null,
                        material_shapes: null,
                        material_dimension: null
                    }]
                },
                manufacturing_requirement: {
                    deadline_date: new Date(),
                    special_notes: "",
                    logistics_support: false,
                    attachment: "",
                    manufacturing_requests: [{
                        quantity: null,
                        finishing_type: null,
                        finishing_color: null,
                        manufacturing_operation: null,
                        manufacturing_process: null
                    }]
                },
                // testing_requirement: {
                //     quantity: '',
                //     deadline_date: "",
                //     logistics_support: "",
                //     special_notes: "",
                //     attachment: "",
                //     testing_requests: [
                //         {
                //             testing_for: "",
                //             testing_operation: "",
                //             testing_type: ""
                //         }
                //     ]
                // },
                // solution_provider_requirement: {
                //     deadline_date: "",
                //     special_notes: "",
                //     attachment: "",
                //     solution_provider_requests: [
                //         {
                //             solution_provider_service: "",
                //             solution_provider_type: "",
                //             solution_provider_industry: "",
                //         }
                //     ]
                // },
                // resource_requirement: {
                //     duration_from: "",
                //     duration_to: '',
                //     special_notes: "",
                //     resource_requests: [
                //         {
                //             resource_industry: "",
                //             resource_competency: "",
                //             resource_type: ""
                //         }
                //     ]
                // }
            }
        }
    }
    sections = ["Material Suppliers",
        "Manufacturers",
        "Manufacturer with Material",
        "Material and Product Testing",
        "Solution Providers",
        "Resources",]

    callValue(data) {
        if (this.state.categories.length === 0) {
            this.setState({
                categories: data.businesses[0].categories
            })
        }
    }
    handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        this.setState(prevState => ({
            ...prevState,
            request: {
                ...prevState.request,
                [name]: value
            }
        }))
    }

    handleMaterialRequirement = (e, type) => {
        const name = e.target.name;
        const value = e.target.type === 'number' ? parseInt(e.target.value) : (type === "bool") ? JSON.parse(e.target.value) : e.target.value;
        this.setState(prevState => ({
            ...prevState,
            request: {
                ...prevState.request,
                material_requirement: {
                    ...prevState.request.material_requirement,
                    [name]: value
                }
            }
        }))
    }
    handleDate(e, setName) {
        this.setState(prevState => ({
            ...prevState,
            request: {
                ...prevState.request,
                [setName]: {
                    ...prevState.request[setName],
                    deadline_date: new Date(e)
                }
            }
        }))
    }
    handleManufactureRequirement = (e, type) => {
        const name = e.target.name;
        const value = e.target.type === 'number' ? parseInt(e.target.value) : (type === "bool") ? JSON.parse(e.target.value) : e.target.value;
        this.setState(prevState => ({
            ...prevState,
            request: {
                ...prevState.request,
                manufacturing_requirement: {
                    ...prevState.request.manufacturing_requirement,
                    [name]: value
                }
            }
        }))
    }
    handleMaterialRequest = (e, key) => {
        const name = e.target.name;
        const value = e.target.value;
        this.state.request.material_requirement.material_requests[key][name] = value;
        this.setState({
            ...this.state
        }, () => {
            console.log(this.state)
        })
    }
    handleManufactureRequest = (e, key) => {
        const name = e.target.name;
        const value = e.target.value;
        this.state.request.manufacturing_requirement.manufacturing_requests[key][name] = value;
        this.setState({
            ...this.state
        })
    }
    handleResourceRequest = (e, key) => {
        const name = e.target.name;
        const value = e.target.value;
        this.state.request.resource_requirement.resource_requests[key][name] = value;
        this.setState({
            ...this.state
        }, () => {
            console.log(this.state)
        })
    }
    handleTestingRequirement = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        this.setState(prevState => ({
            ...prevState,
            request: {
                ...prevState.request,
                testing_requirement: {
                    ...prevState.request.testing_requirement,
                    [name]: value
                }
            }
        }))
    }
    handleSolutionRequirement = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        this.setState(prevState => ({
            ...prevState,
            request: {
                ...prevState.request,
                solution_provider_requirement: {
                    ...prevState.request.solution_provider_requirement,
                    [name]: value
                }
            }
        }))
    }
    handleTestingRequest = (e, key) => {
        const name = e.target.name;
        const value = e.target.value;
        this.state.request.testing_requirement.testing_requests[key][name] = value;
        this.setState({
            ...this.state
        })
    }
    handleSolutionRequest = (e, key) => {
        const name = e.target.name;
        const value = e.target.value;
        this.state.request.solution_provider_requirement.solution_provider_requests[key][name] = value;
        this.setState({
            ...this.state
        })
    }
    handleSubmit = async () => {
        if (this.validator.allValid()) {
            // alert('asdfsadf')
        } else {
            this.validator.showMessages();
            window.scrollTo({ top: 0, behavior: 'smooth' });
            this.forceUpdate();
        }
    }
    removeTesting = (index) => {
        this.state.request.testing_requirement.testing_requests.splice(index, 1);
        this.setState({
            ...this.state
        })
    }
    addTesting = () => {
        let array = {
            testing_for: "",
            testing_operation: "",
            testing_type: ""
        }
        this.state.request.testing_requirement.testing_requests.push(array);
        this.setState({
            ...this.state
        })
    }
    addResource = () => {
        let array = {
            resource_industry: "",
            resource_competency: "",
            resource_type: ""
        }
        this.state.request.resource_requirement.resource_requests.push(array);
        this.setState({
            ...this.state
        })
    }
    removeResource = (index) => {
        this.state.request.resource_requirement.resource_requests.splice(index, 1);
        this.setState({
            ...this.state
        })
    }
    addSolution = () => {
        let array = {
            solution_provider_service: "",
            solution_provider_type: "",
            solution_provider_industry: "",
        }
        this.state.request.solution_provider_requirement.solution_provider_requests.push(array);
        this.setState({
            ...this.state
        })
    }
    handleMaterialDimension = (e, key, map, listAll) => {
        let name = [e.target.name] + key;
        this.state.materialDimension[name] = e.target.value;
        // let assumeValue = this.state.
        this.state.request.material_requirement.material_requests[key].material_dimension = ''
        listAll.map((item, keyList) => {
            this.state.request.material_requirement.material_requests[key].material_dimension =
                this.state.request.material_requirement.material_requests[key].material_dimension.concat(`${item.name} = ${(this.state.materialDimension[item.name + key]) ? this.state.materialDimension[item.name + key] : ""}`)
        })
        this.setState({
            ...this.state
        }, () => {
            console.log(this.state)
        })
    }
    removeSolution = (index) => {
        this.state.request.solution_provider_requirement.solution_provider_requests.splice(index, 1);
        this.setState({
            ...this.state
        })
    }
    addManufacture = () => {
        this.setState({
            isFalse: true
        })
        let array = {
            finishing_type: "",
            finishing_color: "",
            manufacturing_operation: "",
            manufacturing_process: "",
            quantity: null
        }
        this.state.request.manufacturing_requirement.manufacturing_requests.push(array);
        this.setState({
            ...this.state
        }, () => {
            this.setState({
                isFalse: false
            })
        })
    }
    removeManufacture = (index) => {
        this.state.request.manufacturing_requirement.manufacturing_requests.splice(index, 1);
        this.setState({
            ...this.state
        })
    }
    addMaterial = () => {
        this.setState({
            isFalse: true
        })
        const array = {
            material_type: "",
            material_grade: "",
            material_shapes: "",
            quantity: null,
            material_dimension: ""
        }
        this.state.request.material_requirement.material_requests.push(array);
        this.setState({
            ...this.state
        }, () => {
            this.setState({
                isFalse: false
            })
        })
    }
    handleSectionMan = (type) => {
        this.state.sectionMand[type] = !this.state.sectionMand[type]
        this.setState({
            ...this.state
        })
    }
    removeMaterial = (index) => {
        this.state.request.material_requirement.material_requests.splice(index, 1);
        this.setState({
            ...this.state
        })
    }
    handleDocsChange = async (e, name) => {
        const data = new FormData();
        if ((e.target.files[0].type !== "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")) {            data.append('files', e.target.files[0])
            data.append('files', e.target.files[0])
            this.state.attachments[name] = e.target.files[0];

            this.setState({
                isUploading: true
            })
            fetch("https://backend.jobcards.in/upload",
                {
                    method: "POST",
                    body: data
                }).then(response => response.json())
                .then(resData => {
                    this.state.request[name].attachment = resData[0]._id;
                    this.setState({
                        isUploading: false
                    })
                })
        } else {
            toast.error("Recommended - Zip Files")
        }
    }
    removeAttachment(name) {
        document.getElementById(name).value = '';
        this.state.request[name].attachment = '';
        this.state.attachments[name] = '';
        this.setState({
            ...this.state
        })
    }


    render() {

        this.validator.purgeFields();
        return (
            <main id="main-content" tabIndex="-1" role="main">
                <Query query={GET_ONEPOSTS} variables={{ id: this.state.businessId }} context={{ useMultipart: true }}>
                    {({ loading, data }) => !loading && (
                        <div>
                            {this.callValue(data)}
                        </div>
                    )}
                </Query>
                <div className="hero --no-padding-bottom">
                    <div className="hero__inner">
                        <h1 className="hero__heading heading-xl u-text-align-center js-underline-container js-toggle-on-scroll --is-in-view custom-page-heading">
                            Submit Your Requirement
                        </h1>
                        <p className="selected-para">You have selected {this.state.noOfBusiness} businesses</p>
                        <div className="form-div">
                            <h3 className="form-heading">Tell us about your company</h3>
                            <div className="floating-form__field">
                                <input type="text" className="field" onChange={e => this.handleChange(e)} name="company_name" id="company_name" value={this.state.request.company_name} placeholder="&nbsp;" />
                                <label className="input-label1" for="company_name">Company Name<span>*</span></label>
                                {this.validator.message('company_name', this.state.request.company_name, 'required')}
                            </div>
                            <div className="floating-form__field">
                                <input type="text" className="field" onChange={e => this.handleChange(e)} name="name" id="p_name" value={this.state.request.name} placeholder="&nbsp;" />
                                <label className="input-label1" for="p_name">Name<span>*</span></label>
                                {this.validator.message('name', this.state.request.name, 'required')}

                            </div>
                            <div className="floating-form__field">
                                <input type="text" className="field" onChange={e => this.handleChange(e)} name="location" id="p_location" value={this.state.request.location} placeholder="&nbsp;" />
                                <label className="input-label1" for="p_location">Location<span>*</span></label>
                                {this.validator.message('Location', this.state.request.location, 'required')}

                            </div>
                            <div className="floating-form__field">
                                <input type="number" className="field" name="phone" onChange={e => this.handleChange(e)} value={this.state.request.phone} id="phone_num" placeholder="&nbsp;" />
                                <label className="input-label1" for="phone_num">Phone Number<span>*</span></label>
                                {this.validator.message('phone', this.state.request.phone, 'required|integer')}
                            </div>
                            <div className="floating-form__field">
                                <input type="email" className="field" name="email" onChange={e => this.handleChange(e)} value={this.state.request.email} placeholder="&nbsp;" id="email_id" />
                                <label className="input-label1" for="email_id">Email Address<span>*</span></label>
                                {this.validator.message('email', this.state.request.email, 'required|email')}

                            </div>


                            {this.state.categories.filter(resp => (resp.name === this.sections[0]) || (resp.name === this.sections[2])).length > 0 &&
                                <div>
                                    <h3 className="form-heading"><label className="checkbox-container">Material/Suppliers Requirement
                                <input type="checkbox" checked={this.state.sectionMand.one} onChange={() => this.handleSectionMan("one")} />
                                        <span className="checkmark"></span>
                                    </label></h3>
                                    <div style={{ display: "block" }}>
                                        {!this.isFalse && this.state.request.material_requirement.material_requests.map((listItem, key) => (
                                            <div className="duplicate-div" key={key}>






                                                <div className="floating-form__field floating-form__field_select">
                                                    <Query query={GET_MATERIALTYPE} >
                                                        {({ loading, data }) => !loading && (
                                                            <select className="field" name="material_type" onChange={e => this.handleMaterialRequest(e, key)}>
                                                                <option>Select</option>
                                                                {data.materialTypes.map((list, key) => (
                                                                    <option key={key} value={list.id} selected={(list.id === listItem.material_type) ? true : false}>{list.name}</option>
                                                                ))}
                                                            </select>
                                                        )}
                                                    </Query>
                                                    <label className="input-label1">Type<span>*</span></label>
                                                    {this.state.sectionMand.one &&
                                                        this.validator.message('material_type', this.state.request.material_requirement.material_requests[key].material_type, 'required')}
                                                </div>







                                                {this.state.request.material_requirement.material_requests[key].material_type &&
                                                    <div className="floating-form__field floating-form__field_select">
                                                        <Query query={GET_GRADEBY_TYPE} variables={{ id: this.state.request.material_requirement.material_requests[key].material_type }} >
                                                            {({ loading, data }) => !loading && (
                                                                <select className="field" name="material_grade" onChange={e => this.handleMaterialRequest(e, key)}>
                                                                    <option>Select</option>
                                                                    {data.materialTypes[0].material_grades.map((list, key) => (
                                                                        <option key={key} value={list.id} selected={(list.id === listItem.material_grade) ? true : false}>{list.name}</option>
                                                                    ))}
                                                                </select>
                                                            )}
                                                        </Query>
                                                        <label className="input-label1">Grade<span>*</span></label>
                                                        {this.state.sectionMand.one && this.validator.message('material_grade', this.state.request.material_requirement.material_requests[key].material_grade, 'required')}
                                                    </div>}







                                                {this.state.request.material_requirement.material_requests[key].material_grade &&
                                                    <div className="floating-form__field floating-form__field_select">
                                                        <Query query={GET_SHAPEBY_TYPE_NEW} variables={{
                                                            id: this.state.request.material_requirement.material_requests[key].material_grade
                                                        }}>
                                                            {({ loading, data }) => !loading && (
                                                                <select className="field" name="material_shapes" onChange={e => this.handleMaterialRequest(e, key)}>
                                                                    <option>Select</option>
                                                                    {data.materialGrades[0].material_shapes.map((list, key) => (
                                                                        <option key={key} value={list.id} selected={(list.id === listItem.material_shapes) ? true : false}>{list.name}</option>
                                                                    ))}
                                                                </select>
                                                            )}
                                                        </Query>
                                                        <label className="input-label1">Shape<span>*</span></label>
                                                        {this.state.sectionMand.one && this.validator.message('material_shapes', this.state.request.material_requirement.material_requests[key].material_shapes, 'required')}
                                                    </div>}







                                                {this.state.request.material_requirement.material_requests[key].material_shapes &&
                                                    <Query query={GET_DIMENSION_TYPE_NEW} variables={{
                                                        id: this.state.request.material_requirement.material_requests[key].material_shapes
                                                    }}>
                                                        {({ loading, data }) => !loading && (
                                                            <React.Fragment key={key}>
                                                                <label style={{ textAlign: "center", marginBottom: "15px", display: "block" }}>Material dimension in mm</label>
                                                                {data.materialShapes[0].material_dimensions.map((list, keyMap) => {
                                                                    return (
                                                                        <div className="floating-form__field" key={keyMap}>
                                                                            <input type="text" onChange={e => this.handleMaterialDimension(e, key, keyMap, data.materialShapes[0].material_dimensions)} className="field" name={list.name} value={(this.state.materialDimension[list.name + key]) ? this.state.materialDimension[list.name + key] : ""} placeholder="&nbsp;" id={"material_dim" + keyMap + key} />
                                                                            <label className={"input-label1"} for={"material_dim" + keyMap + key}>{list.name}</label>
                                                                            {/* {this.state.sectionMand.one && this.validator.message('name', this.state.request.material_requirement.material_requests[key].material_dimension, 'required')} */}
                                                                        </div>
                                                                    )
                                                                })}
                                                            </React.Fragment>
                                                        )}
                                                    </Query>
                                                }




                                                <div className="floating-form__field">
                                                    <input type="number" className="field" onChange={e => this.handleMaterialRequest(e, key)} name="quantity" value={this.state.request.material_requirement.material_requests[key].quantity} placeholder="&nbsp;" id="quan" />
                                                    <label className="input-label1" for="quan">Quantity<span>*</span></label>
                                                    {this.validator.message('quantity', this.state.request.material_requirement.material_requests[key].quantity, 'required')}
                                                </div>
                                                {this.state.request.material_requirement.material_requests.length > 1 &&
                                                    <div className="text-right"><button onClick={() => this.removeMaterial(key)} className="btn btn-add remove"><img src="/images/remove.svg" alt="" />Remove</button></div>}
                                            </div>
                                        ))
                                        }
                                        <div className="text-right">
                                            <button onClick={() => this.addMaterial()} className="btn btn-add"><img src="/images/add.svg" alt="" />Add</button>
                                        </div>

                                        <div className="floating-form__field floating-form__field-date">

                                            <DatePicker
                                                name="deadline_date"
                                                className="field"
                                                minDate={new Date()}
                                                selected={this.state.request.material_requirement.deadline_date}
                                                onChange={e => this.handleDate(e, "material_requirement")}
                                            />
                                            <label className="input-label1">Need By Date<span>*</span></label>
                                            {this.state.sectionMand.one && this.validator.message('deadline_date', this.state.request.material_requirement.deadline_date, 'required')}
                                        </div>
                                        <div className="floating-form__field floating-form__field_select">
                                            <select className="field" name="material_test_report" onChange={e => this.handleMaterialRequirement(e, "bool")}>
                                                {/* <option>Select</option> */}
                                                {/* <option value={false}>No</option>
                                                <option value={true}>Yes</option> */}

                                                <option selected={this.state.request.material_requirement.material_test_report === true} value={true}>Yes</option>
                                                <option selected={this.state.request.material_requirement.material_test_report === false} value={false}>No</option>
                                            </select>
                                            <label className="input-label1">Material Test Report<span>*</span></label>
                                            {this.state.sectionMand.one && this.validator.message('material_test_report', this.state.request.material_requirement.material_test_report, 'required')}

                                        </div>
                                        <div className="floating-form__field floating-form__field_select">
                                            <select className="field" name="logistics_support" onChange={e => this.handleMaterialRequirement(e, "bool")}>
                                                {/* <option>Select</option> */}
                                                <option selected={this.state.request.material_requirement.logistics_support === true} value={true}>Yes</option>
                                                <option selected={this.state.request.material_requirement.logistics_support === false} value={false}>No</option>
                                            </select>
                                            <label className="input-label1">Logistics Support<span>*</span></label>
                                            {this.state.sectionMand.one && this.validator.message('logistics_support', this.state.request.material_requirement.logistics_support, 'required')}
                                        </div>
                                        {/* <div className="floating-form__field">
                                            <textarea className="field" >Special Notes</textarea>
                                        </div> */}
                                        <div className="floating-form__field">
                                            <div className="attachment-input">
                                                <input type="file" id="material_requirement" onChange={e => this.handleDocsChange(e, "material_requirement")} name="material_requirement" />
                                                <p><img src="/images/attachment.svg" alt="" />  Attach Documents</p>
                                            </div>

                                            {(this.state.attachments.material_requirement?.name) ?
                                                <div className="attachment-remove">
                                                    <p>{this.state.attachments.material_requirement?.name}</p> <button onClick={() => this.removeAttachment("material_requirement")}>&times;</button>
                                                </div>

                                                : <p style={{ fontSize: "13px", color: "gray" }}>Recommended - Zip Files</p>
                                            }
                                            {/* {this.validator.message('attachment', this.state.request.material_requirement.attachment, 'required')} */}

                                        </div>
                                    </div>
                                </div>
                            }

                            {this.state.categories.filter(resp => (resp.name === this.sections[1]) || (resp.name === this.sections[2])).length > 0 &&
                                <div>
                                    <h3 className="form-heading"><label className="checkbox-container">Manufacturer Requirement
                                <input type="checkbox" checked={this.state.sectionMand.two} onChange={() => this.handleSectionMan("two")} />
                                        <span className="checkmark"></span>
                                    </label></h3>
                                    <div style={{ display: "block" }}>
                                        {!this.isFalse && this.state.request.manufacturing_requirement.manufacturing_requests.map((listItem, key) => (
                                            <div className="duplicate-div" key={key}>

                                                <div className="floating-form__field floating-form__field_select">
                                                    <Query query={GET_MANUFACTURING_PROCESS} >
                                                        {({ loading, data }) => !loading && (
                                                            <select className="field" name="manufacturing_process" onChange={(e) => this.handleManufactureRequest(e, key)}>
                                                                <option>Select </option>
                                                                {data.manufacturingProcesses.map((list, key) => (
                                                                    <option key={key} selected={(list.id === listItem.manufacturing_process) ? true : false} value={list.id}>{list.name}</option>
                                                                ))}
                                                            </select>
                                                        )}
                                                    </Query>
                                                    <label className="input-label1">Process Type<span>*</span></label>
                                                    {this.state.sectionMand.two && this.validator.message('manufacturing_process', this.state.request.manufacturing_requirement.manufacturing_requests[key].manufacturing_process, 'required')}

                                                </div>
                                                {this.state.request.manufacturing_requirement.manufacturing_requests[key].manufacturing_process &&
                                                    <div className="floating-form__field floating-form__field_select">
                                                        <Query query={GET_MANUFACTURING_OPERATION} variables={{ id: this.state.request.manufacturing_requirement.manufacturing_requests[key].manufacturing_process }}>
                                                            {({ loading, data }) => !loading && (
                                                                <select className="field" name="manufacturing_operation" onChange={(e) => this.handleManufactureRequest(e, key)}>
                                                                    <option>Select</option>
                                                                    {data.manufacturingProcesses[0].manufacturing_operations.map((list, key) => (
                                                                        <option key={key} value={list.id} selected={(list.id === listItem.manufacturing_operation) ? true : false}>{list.name}</option>
                                                                    ))}
                                                                </select>
                                                            )}
                                                        </Query>
                                                        <label className="input-label1">Operations<span>*</span></label>
                                                        {this.state.sectionMand.two && this.validator.message('manufacturing_operation', this.state.request.manufacturing_requirement.manufacturing_requests[key].manufacturing_operation, 'required')}

                                                    </div>}
                                                <div className="floating-form__field floating-form__field_select">
                                                    <Query query={GET_MANUFACTURING_FINISHING_TYPE} >
                                                        {({ loading, data }) => !loading && (
                                                            <select className="field" name="finishing_type" onChange={(e) => this.handleManufactureRequest(e, key)}>
                                                                <option>Select</option>
                                                                {data.finishingTypes.map((list, key) => (
                                                                    <option key={key} value={list.id} selected={(list.id === listItem.finishing_type) ? true : false}>{list.name}</option>
                                                                ))}
                                                            </select>
                                                        )}
                                                    </Query>
                                                    <label className="input-label1">Finishing Type<span>*</span></label>
                                                    {this.state.sectionMand.two && this.validator.message('finishing_type', this.state.request.manufacturing_requirement.manufacturing_requests[key].finishing_type, 'required')}
                                                </div>


                                                {this.state.request.manufacturing_requirement.manufacturing_requests[key].finishing_type &&
                                                    <div className="floating-form__field floating-form__field_select">
                                                        <Query query={GET_MANUFACTURING_FINISHING_COLOR} variables={{ id: this.state.request.manufacturing_requirement.manufacturing_requests[key].finishing_type }}>
                                                            {({ loading, data }) => !loading && (
                                                                <select className="field" name="finishing_color" onChange={(e) => this.handleManufactureRequest(e, key)}>
                                                                    <option>Select</option>
                                                                    {data.finishingTypes[0].finishing_colors.map((list, key) => (
                                                                        <option key={key} value={list.id} selected={(list.id === listItem.finishing_color) ? true : false}>{list.name}</option>
                                                                    ))}
                                                                </select>
                                                            )}
                                                        </Query>
                                                        <label className="input-label1">Finishing Color<span>*</span></label>
                                                        {this.state.sectionMand.two && this.validator.message('finishing_color', this.state.request.manufacturing_requirement.manufacturing_requests[key].finishing_color, 'required')}

                                                    </div>
                                                }



                                                <div className="floating-form__field">
                                                    <input type="number" className="field" onChange={e => this.handleManufactureRequest(e, key)} name="quantity" value={this.state.request.manufacturing_requirement.manufacturing_requests[key].quantity} placeholder="&nbsp;" id="quan" />
                                                    <label className="input-label1" for="quan">Quantity<span>*</span></label>
                                                    {this.state.sectionMand.two && this.validator.message('quantity', this.state.request.manufacturing_requirement.manufacturing_requests[key].quantity, 'required')}
                                                </div>


                                                {this.state.request.manufacturing_requirement.manufacturing_requests.length > 1 &&
                                                    <div className="text-right">
                                                        <button onClick={() => this.removeManufacture(key)} className="btn btn-add remove"><img src="/images/remove.svg" alt="" />Remove</button></div>}
                                            </div>))}
                                        <div className="text-right">
                                            <button onClick={() => this.addManufacture()} className="btn btn-add"><img src="/images/add.svg" alt="" />Add</button>
                                        </div>

                                        <div className="floating-form__field floating-form__field-date">
                                            <DatePicker
                                                minDate={new Date()}
                                                name="deadline_date"
                                                className="field"
                                                selected={this.state.request.manufacturing_requirement.deadline_date}
                                                onChange={e => this.handleDate(e, "manufacturing_requirement")}
                                            />
                                            <label className="input-label1">Need By Date<span>*</span></label>
                                            {/* <input type="date" className="field" onChange={e => this.handleDate(e, "manufacturing_requirement")} name="deadline_date" value={this.state.request.manufacturing_requirement.deadline_date} placeholder="Dead Line Date" /> */}
                                            {this.state.sectionMand.two && this.validator.message('deadline_date', this.state.request.manufacturing_requirement.deadline_date, 'required')}
                                        </div>
                                        <div className="floating-form__field floating-form__field_select">
                                            <select className="field" name="logistics_support" onChange={e => this.handleManufactureRequirement(e, "bool")}>
                                                {/* <option>Select</option> */}
                                                <option selected={this.state.request.manufacturing_requirement.logistics_support === true} value={true}>Yes</option>
                                                <option selected={this.state.request.manufacturing_requirement.logistics_support === false} value={false}>No</option>
                                            </select>
                                            <label className="input-label1">Logistics Support<span>*</span></label>
                                            {this.state.sectionMand.two && this.validator.message('logistics_support', this.state.request.manufacturing_requirement.logistics_support, 'required')}
                                        </div>
                                        {/* <div className="floating-form__field">
                                            <textarea className="field" >Special Notes</textarea>
                                        </div> */}
                                        <div className="floating-form__field">
                                            <div className="attachment-input">
                                                <input type="file" id="manufacturing_requirement" onChange={e => this.handleDocsChange(e, "manufacturing_requirement")} name="manufacturing_requirement" />
                                                <p><img src="/images/attachment.svg" alt="" />  Attach Documents</p>
                                            </div>
                                            {(this.state.attachments.manufacturing_requirement?.name) ?
                                                <div className="attachment-remove">
                                                    <p>{this.state.attachments.manufacturing_requirement?.name}</p> <button onClick={() => this.removeAttachment("manufacturing_requirement")}>&times;</button>
                                                </div>
                                                : <p style={{ fontSize: "13px", color: "gray" }}>Recommended - Zip Files</p>
                                            }
                                            {/* {this.validator.message('attachment', this.state.request.manufacturing_requirement.attachment, 'required')} */}

                                        </div>
                                    </div>
                                </div>}
                            {/* 
                            {this.state.categories.filter(resp => resp.name === this.sections[2]).length > 0 &&
                                <div>
                                    <h3 className="form-heading"><label className="checkbox-container">Material and Product Testing Requirement
                                <input type="checkbox" />
                                        <span className="checkmark"></span>
                                    </label></h3>
                                    <div style={{ display: "block" }}>
                                        {!this.isFalse && this.state.request.testing_requirement.testing_requests.map((listItem, key) => (
                                            <div key={key}>

                                                <div className="floating-form__field floating-form__field_select">
                                                    <Query query={GET_TESTING} >
                                                        {({ loading, data }) => !loading && (
                                                            <select className="field" name="testing_for" onChange={e => this.handleTestingRequest(e, key)}>
                                                                <option>Select Testing For</option>
                                                                {data.testingFors.map((list, key) => (
                                                                    <option
                                                                        selected={(list.id === listItem.testing_for) ? true : false}
                                                                        key={key} value={list.id}>{list.name}</option>
                                                                ))}
                                                            </select>
                                                        )}
                                                    </Query>
                                                    {this.validator.message('testing_for', this.state.request.testing_requirement.testing_requests[key].testing_for, 'required')}

                                                </div>
                                                {this.state.request.testing_requirement.testing_requests[key].testing_for &&
                                                    <div className="floating-form__field floating-form__field_select">
                                                        <Query query={GET_TESTING_FORBY_ID} variables={{ id: this.state.request.testing_requirement.testing_requests[key].testing_for }}>
                                                            {({ loading, data }) => !loading && (
                                                                <select className="field" name="testing_operation" onChange={e => this.handleTestingRequest(e, key)}>
                                                                    <option>Select Testing Operation</option>
                                                                    {data.testingFors[0].testing_operations.map((list, key) => (
                                                                        <option key={key}
                                                                            selected={(list.id === listItem.testing_operation) ? true : false}
                                                                            value={list.id}>{list.name}</option>
                                                                    ))}
                                                                </select>
                                                            )}
                                                        </Query>
                                                        {this.validator.message('testing_operation', this.state.request.testing_requirement.testing_requests[key].testing_operation, 'required')}

                                                    </div>}
                                                {this.state.request.testing_requirement.testing_requests[key].testing_operation &&
                                                    <div className="floating-form__field floating-form__field_select">
                                                        <Query query={GET_TESTING_OPERATION_ID_TYPE} variables={{ id: this.state.request.testing_requirement.testing_requests[key].testing_operation }}>
                                                            {({ loading, data }) => !loading && (
                                                                <select className="field" name="testing_types" onChange={e => this.handleTestingRequest(e, key)}>
                                                                    <option>Select Testing Type</option>
                                                                    {data.testingOperations[0].testing_types.map((list, key) => (
                                                                        <option key={key}
                                                                            selected={(list.id === listItem.testing_types) ? true : false}
                                                                            value={list.id}>{list.name}</option>
                                                                    ))}
                                                                </select>
                                                            )}
                                                        </Query>
                                                        {this.validator.message('testing_types', this.state.request.testing_requirement.testing_requests[key].testing_types, 'required')}

                                                    </div>}
                                                } {this.state.request.testing_requirement.testing_requests.length > 1 &&
                                                    <button onClick={() => this.removeTesting(key)} className="btn btn-danger"> Remove</button>}
                                            </div>))}
                                        <button onClick={() => this.addTesting()} className="btn btn-primary"> Add</button>
                                        <div className="floating-form__field">
                                            <input type="text" className="field" onChange={e => this.handleTestingRequirement(e)} name="quantity" value={this.state.request.manufacturing_requirement.quantity} placeholder="Quantity" />
                                            {this.validator.message('quantity', this.state.request.manufacturing_requirement.quantity, 'required')}
                                        </div>
                                        <div className="floating-form__field">
                                            <input type="date" className="field" onChange={e => this.handleTestingRequirement(e)} name="deadline_date" value={this.state.request.manufacturing_requirement.deadline_date} placeholder="Dead Line Date" />
                                            {this.validator.message('deadline_date', this.state.request.manufacturing_requirement.deadline_date, 'required')}
                                        </div>
                                        <div className="floating-form__field floating-form__field_select">
                                            <select className="field" name="logistics_support" onChange={e => this.handleTestingRequirement(e)}>
                                                <option>Select Logistics Support</option>
                                                <option value={true}>Yes</option>
                                                <option value={false}>No</option>
                                            </select>
                                            {this.validator.message('logistics_support', this.state.request.manufacturing_requirement.logistics_support, 'required')}
                                        </div>
                                        <div className="floating-form__field">
                                            <textarea className="field" >Special Notes</textarea>
                                        </div>
                                        <div className="floating-form__field">
                                            <div className="attachment-input">
                                                <input type="file" />
                                                <p><img src="/images/attachment.svg" alt="" />  Attach Documents</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>}
                            {this.state.categories.filter(resp => resp.name === this.sections[3]).length > 0 &&
                                <div>
                                    <h3 className="form-heading"><label className="checkbox-container">Solution
                                <input type="checkbox" />
                                        <span className="checkmark"></span>
                                    </label></h3>
                                    <div style={{ display: "block" }}>
                                        {!this.isFalse && this.state.request.solution_provider_requirement.solution_provider_requests.map((listItem, key) => (
                                            <div key={key}>

                                                <div className="floating-form__field floating-form__field_select">
                                                    <Query query={GET_SOLUTION_PROVIDER} >
                                                        {({ loading, data }) => !loading && (
                                                            <select className="field" name="solution_provider_industry" onChange={e => this.handleSolutionRequest(e, key)}>
                                                                <option>Select Industry</option>
                                                                {data.solutionProviderIndustries.map((list, key) => (
                                                                    <option
                                                                        selected={(list.id === listItem.solution_provider_industry) ? true : false}
                                                                        key={key} value={list.id}>{list.name}</option>
                                                                ))}
                                                            </select>
                                                        )}
                                                    </Query>
                                                    {this.validator.message('solution_provider_industry', this.state.request.solution_provider_requirement.solution_provider_requests[key].solution_provider_industry, 'required')}

                                                </div>
                                                {this.state.request.solution_provider_requirement.solution_provider_requests[key].solution_provider_industry &&
                                                    <div className="floating-form__field floating-form__field_select">
                                                        <Query query={GET_SOLUTION_PROVIDER_FORBY_ID} variables={{ id: this.state.request.solution_provider_requirement.solution_provider_requests[key].solution_provider_industry }}>
                                                            {({ loading, data }) => !loading && (
                                                                <select className="field" name="solution_provider_services" onChange={e => this.handleSolutionRequest(e, key)}>
                                                                    <option>Select Services</option>
                                                                    {data.solutionProviderIndustries[0].solution_provider_services.map((list, key) => (
                                                                        <option key={key}
                                                                            selected={(list.id === listItem.solution_provider_services) ? true : false}
                                                                            value={list.id}>{list.name}</option>
                                                                    ))}
                                                                </select>
                                                            )}
                                                        </Query>
                                                        {this.validator.message('solution_provider_services', this.state.request.solution_provider_requirement.solution_provider_requests[key].solution_provider_services, 'required')}

                                                    </div>}
                                                {this.state.request.solution_provider_requirement.solution_provider_requests[key].solution_provider_services &&
                                                    <div className="floating-form__field floating-form__field_select">
                                                        <Query query={GET_SOLUTION_INSD_ID_TYPE} variables={{ id: this.state.request.solution_provider_requirement.solution_provider_requests[key].solution_provider_services }}>
                                                            {({ loading, data }) => !loading && (
                                                                <select className="field" name="solution_provider_types" onChange={e => this.handleSolutionRequest(e, key)}>
                                                                    <option>Select Testing Type</option>
                                                                    {data.solutionProviderServices[0].solution_provider_types.map((list, key) => (
                                                                        <option key={key}
                                                                            selected={(list.id === listItem.solution_provider_types) ? true : false}
                                                                            value={list.id}>{list.name}</option>
                                                                    ))}
                                                                </select>
                                                            )}
                                                        </Query>
                                                        {this.validator.message('solution_provider_types', this.state.request.solution_provider_requirement.solution_provider_requests[key].solution_provider_types, 'required')}

                                                    </div>}
                                                 {this.state.request.solution_provider_requirement.solution_provider_requests.length > 1 &&
                                                    <button onClick={() => this.removeSolution(key)} className="btn btn-danger"> Remove</button>}
                                            </div>))}
                                        <button onClick={() => this.addSolution()} className="btn btn-primary"> Add</button>

                                        <div className="floating-form__field">
                                            <input type="date" className="field" onChange={e => this.handleSolutionRequirement(e)} name="deadline_date" value={this.state.request.manufacturing_requirement.deadline_date} placeholder="Dead Line Date" />
                                            {this.validator.message('deadline_date', this.state.request.manufacturing_requirement.deadline_date, 'required')}
                                        </div>
                                        <div className="floating-form__field">
                                            <textarea className="field" >Special Notes</textarea>
                                        </div>
                                    </div>
                                </div>}
                            {this.state.categories.filter(resp => resp.name === this.sections[4]).length > 0 &&
                                <div>
                                    <h3 className="form-heading"><label className="checkbox-container">Resource Requirement
                                <input type="checkbox" />
                                        <span className="checkmark"></span>
                                    </label></h3>
                                    <div style={{ display: "block" }}>
                                        {!this.isFalse && this.state.request.resource_requirement.resource_requests.map((listItem, key) => (
                                            <div key={key}>

                                                <div className="floating-form__field floating-form__field_select">
                                                    <Query query={GET_RESOURCES} >
                                                        {({ loading, data }) => !loading && (
                                                            <select className="field" name="resource_industry" onChange={e => this.handleResourceRequest(e, key)}>
                                                                <option>Select Industry</option>
                                                                {data.resourceIndustries.map((list, key) => (
                                                                    <option key={key}
                                                                        selected={(list.id === listItem.resource_industry) ? true : false}
                                                                        value={list.id}>{list.name}</option>
                                                                ))}
                                                            </select>
                                                        )}
                                                    </Query>
                                                    {this.validator.message('resource_industry', this.state.request.resource_requirement.resource_requests[key].resource_industry, 'required')}

                                                </div>
                                                {this.state.request.resource_requirement.resource_requests[key].resource_industry &&
                                                    <div className="floating-form__field floating-form__field_select">
                                                        <Query query={GET_RESOURCE_PROVIDER_FORBY_ID} variables={{ id: this.state.request.resource_requirement.resource_requests[key].resource_industry }}>
                                                            {({ loading, data }) => !loading && (
                                                                <select className="field" name="resource_competency" onChange={e => this.handleResourceRequest(e, key)}>
                                                                    <option>Select Competency</option>
                                                                    {data.resourceIndustries[0].resource_competencies.map((list, key) => (
                                                                        <option key={key}
                                                                            selected={(list.id === listItem.resource_competency) ? true : false}
                                                                            value={list.id}>{list.name}</option>
                                                                    ))}
                                                                </select>
                                                            )}
                                                        </Query>
                                                        {this.validator.message('resource_competency', this.state.request.resource_requirement.resource_requests[key].resource_competency, 'required')}

                                                    </div>}
                                                {this.state.request.resource_requirement.resource_requests[key].resource_competency &&
                                                    <div className="floating-form__field floating-form__field_select">
                                                        <Query query={GET_RESOURCE_INSD_ID_TYPE} variables={{ id: this.state.request.resource_requirement.resource_requests[key].resource_competency }}>
                                                            {({ loading, data }) => !loading && (
                                                                <select className="field" name="resource_type" onChange={e => this.handleResourceRequest(e, key)}>
                                                                    <option>Select Testing Type</option>
                                                                    {data.resourceCompetencies[0].resource_types.map((list, key) => (
                                                                        <option key={key}
                                                                            selected={(list.id === listItem.resource_type) ? true : false}
                                                                            value={list.id}>{list.name}</option>
                                                                    ))}
                                                                </select>
                                                            )}
                                                        </Query>
                                                        {this.validator.message('resource_type', this.state.request.resource_requirement.resource_requests[key].resource_type, 'required')}

                                                    </div>}
                                                  {this.state.request.resource_requirement.resource_requests.length > 1 &&
                                                    <button onClick={() => this.removeResource(key)} className="btn btn-danger"> Remove</button>}
                                            </div>))}
                                        <button onClick={() => this.addResource()} className="btn btn-primary"> Add</button>

                                        <div className="floating-form__field">
                                            <div className="attachment-input">
                                                <input type="file" />
                                                <p><img src="/images/attachment.svg" alt="" />  Attach Documents</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>}
                            */}
                            {(this.validator.allValid()) ?

                                (this.state.request.material_requirement.material_requests[0].material_type !== null) ?

                                    (<Mutation mutation={NEWQUERY} variables={{
                                        data: {
                                            businesses: this.state.request.businesses,
                                            company_name: this.state.request.company_name,
                                            name: this.state.request.name,
                                            email: this.state.request.email,
                                            phone: this.state.request.phone,
                                            location: this.state.request.location,
                                            material_requirement: {
                                                deadline_date: this.state.request.material_requirement.deadline_date,
                                                special_notes: this.state.request.material_requirement.special_notes,
                                                logistics_support: this.state.request.material_requirement.logistics_support,
                                                material_test_report: this.state.request.material_requirement.material_test_report,
                                                attachment: this.state.request.material_requirement.attachment,
                                                material_requests: this.state.request.material_requirement.material_requests

                                            }
                                        }
                                    }}>
                                        {(postMutation, { data }) => {
                                            if (data) {
                                                toast.success("Request submitted successfully")
                                                this.props.history.push('/')
                                            } return (
                                                <button className="btn btn-submit-now" onClick={postMutation}>
                                                    Submit
                                                </button>
                                            )
                                        }}
                                    </Mutation>)
                                    :

                                    (this.state.request.manufacturing_requirement.manufacturing_requests[0].finishing_type !== null) ?
                                        (<Mutation
                                            mutation={NEWQUERY}
                                            variables={{
                                                data: {
                                                    businesses: this.state.request.businesses,
                                                    company_name: this.state.request.company_name,
                                                    name: this.state.request.name,
                                                    email: this.state.request.email,
                                                    phone: this.state.request.phone,
                                                    location: this.state.request.location,
                                                    manufacturing_requirement: {
                                                        deadline_date: this.state.request.manufacturing_requirement.deadline_date,
                                                        special_notes: this.state.request.manufacturing_requirement.special_notes,
                                                        logistics_support: this.state.request.manufacturing_requirement.logistics_support,
                                                        material_test_report: this.state.request.manufacturing_requirement.material_test_report,
                                                        attachment: this.state.request.manufacturing_requirement.attachment,
                                                        manufacturing_requests: this.state.request.manufacturing_requirement.manufacturing_requests
                                                    }
                                                }
                                            }}>
                                            {(postMutation, { data }) => {
                                                if (data) {
                                                    toast.success("Request submitted successfully")
                                                    this.props.history.push('/')
                                                } return (
                                                    <button className="btn btn-submit-now" onClick={postMutation}>
                                                        Submit
                                                    </button>
                                                )
                                            }}
                                        </Mutation>)

                                        :

                                        (<Mutation
                                            mutation={NEWQUERY}
                                            variables={{
                                                data: this.state.request
                                            }}>
                                            {(postMutation, { data }) => {
                                                if (data) {
                                                    toast.success("Request submitted successfully")
                                                    this.props.history.push('/')
                                                } return (
                                                    <button className="btn btn-submit-now" onClick={postMutation}>
                                                        Submit
                                                    </button>
                                                )
                                            }}
                                        </Mutation>)
                                :

                                (this.state.isUploading) ?
                                    <button className="btn btn-submit-now">File Upload Processing...</button>
                                    :
                                    <button onClick={e => this.handleSubmit()} className="btn btn-submit-now">Submit now</button>

                            }
                        </div>
                    </div>
                </div>
            </main >
        )
    }
}
export default BusinessSubmit