import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import './Slick.css';
import './App.css';
import './Custom.css';
import HomePage from "./_components/home";
import { ToastContainer,toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "react-datepicker/dist/react-datepicker.css";


toast.configure();
function App() {
  return (
    <div className="App">
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        
      />
      <BrowserRouter>
        <>
          <Switch>
            <Route path="/" component={HomePage} />
            {/* <Route path="**" component={PageNotFoundPage} /> */}
          </Switch>
        </>
      </BrowserRouter>
    </div>
  );
}

export default App;
