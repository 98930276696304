import React from 'react';
import Slider from "react-slick";
import { NavLink } from 'react-router-dom';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import MarkdownView from 'react-showdown';


export const GET_POSTS = gql`
  query businesses($id:ID!){
      businesses(where:{id:$id}){
          name
          id
          about
          competency
          logo{
            name,
            url
        }
          categories{
             name
             id
            }
          address{
            city{
                name
            }
            address_line_1
            address_line_2
            state {
                name
            }
            pincode
        } 
      }
  }
`;
class BusinessDetails extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            id: props.match.params.Id
        }
        localStorage.clear();
    }
    handleSubmit = (value) => {
        localStorage.setItem("businesses", JSON.stringify([value]))
        this.props.history.push('/business-submit');
    }
    render() {
        var settings = {
            dots: false,
            infinite: false,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1
        };
        return (
            <main id="main-content" tabIndex="-1" role="main">
                <div style={{ paddingTop: "55px" }}>
                    <Query query={GET_POSTS} variables={{ id: this.state.id }}>
                        {({ loading, data }) => !loading ? (
                            <div className="custom-container custom-container-padding">
                                <NavLink to="/" className="go-back"><img src="/images/arrow_back.svg" alt="" />Go Back</NavLink>
                                {data.businesses.map((item, key) => {
                                    return (
                                        // 
                                        <React.Fragment key={key}>
                                            <Slider {...settings} className="business-slider">
                                                <div>
                                                    {/* <img src={(item.logo) ? `https://backend.jobcards.in${item.logo.url}` : "/images/banner.png"} alt="" style={{ width: "100%" }} /> */}
                                                    <center> <img src={(item.logo) ? `${item.logo.url}` : "/images/banner.png"} alt="" style={{maxWidth:"50%",maxHeight:"50%"}}/></center>
                                                </div>
                                            </Slider>
                                            <div className="half-div">
                                                <div className="business-details">
                                                    <h3 className="business-name">{item.name}</h3>
                                           address     <p className="business-location">
                                                        {item.address ? `${item.address.address_line_1},${item.address.address_line_2},${item.address.city?.name},${item.address.state?.name} - ${item.address.pincode}` : "-"}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="half-div">
                                                <button onClick={() => this.handleSubmit(item.id)} className="btn btn-request">REQUEST QUOTE</button>
                                            </div>
                                            <div className="clearfix"></div>
                                            <div className="category-container">
                                                {item.categories.map((list, key) => (
                                                    <span className="category" key={key}>{list.name}</span>
                                                ))}
                                            </div>
                                           

                                            <hr className="custom-hr" />
                                            <h4 className="business-sub-heading">Competency</h4>
                                            <p className="business-para" className="display-linebreak" >
                                                <MarkdownView
                                                    markdown={item.competency}
                                                    options={{ tables: true, emoji: true }}
                                                />
                                            </p>
                                            <hr className="custom-hr" />
                                            <h4 className="business-sub-heading">About</h4>



                                            <div className="business-para" className="display-linebreak" >
                                                <MarkdownView
                                                    markdown={item.about}
                                                    options={{ tables: true, emoji: true }}
                                                />
                                            </div>

                                        </React.Fragment>
                                    )
                                })}
                            </div>
                        ) :
                            (
                                <div className="loader-div">
                                    <img src="/images/loader.gif" alt="" />
                                </div>
                            )}
                    </Query>
                </div>
            </main>
        )
    }
}
export default BusinessDetails