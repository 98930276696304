import React from "react";
// import { useEffect } from "react";
import BusinessCard from "./common/business-card";
import { Query } from "react-apollo";
import {
  GET_MATERIALTYPE,
  GET_BUSINESSPOSTS,
  GET_POSTS,
  GET_CATEGORY,
  GET_MATERIALGRADE,
  GET_MATERIAL_SHAPE,
  GET_MANUFACTURING_PROCESS,
  GET_MANUFACTURING_OPERATION,
  GET_MANUFACTURING_FINISHING_TYPE,
  GET_MANUFACTURING_FINISHING_COLOR,
  GET_TESTING,
  GET_SOLUTION_PROVIDER,
  GET_RESOURCES,
  GET_GRADEBY_TYPE_NEW,
  GET_SHAPEBY_TYPE_NEW,
  GET_OPERATIONJOB_TYPE_NEW,
} from "./query";
import Select from "react-select";

class MainPages extends React.Component {
  state = {
    isFirstRender: true,
    categoryFilter: false,
    moreFilter: false,
    activeTab: 0,
    chooseItems: [],
    categories: [],
    material_types: [],
    material_shapes: [],
    material_grades: [],
    manufacturingProcessesId: [],
    manufacturingOperationsId: [],
    refetch: false,
    headerScrolled: false,
  };
  headerScroll = () => {
    if (window.scrollY >= 200) {
      this.setState({
        headerScrolled: true
      });
    }
    if (window.scrollY < 200) {
      this.setState({
        headerScrolled: false
      });
    }
  }
  componentDidMount() {
    localStorage.clear();
    window.addEventListener('scroll', this.headerScroll)
  }

  enableTabActive(value) {
    this.setState({
      activeTab: value,
    });
  }
  enableCategoryFilter() {
    this.setState({
      categoryFilter: !this.state.categoryFilter,
      moreFilter: false,
    });
  }
  enableMoreFilter() {
    this.setState({
      categoryFilter: false,
      moreFilter: !this.state.moreFilter,
    });
  }
  handleSelectSingle(e, name, path) {
    this.setState({
      [name]: e,
    });
    // if (document.getElementById(path) !== '' && this.state.manufacturingProcessesId && path !== null) {
    //     document.getElementById('manufactureTypeId').click();
    // }
  }

  handleSubmit = () => {
    if (this.state.chooseItems.length === 0) {
      let errId = document.getElementById("err-msg");
      let errId12 = document.getElementById("err-msg12");
      errId.innerHTML = "Choose atleast one business before you submit";
      errId12.innerHTML = "Choose atleast one business before you submit";
      setTimeout(function () {
        errId.innerHTML = "";
        errId12.innerHTML = "";
      }, 3000);
    } else {
      localStorage.setItem(
        "businesses",
        JSON.stringify(this.state.chooseItems)
      );
      this.props.history.push("/business-submit");
    }
  };
  handleFilterCategory = (value) => {
    localStorage.setItem("id", value);

    const indexO = this.state.categories.indexOf(value);
    if (value === "5f3cf4ee250f7a7e4d8d0ae0") {
      this.state.activeTab = 1;
    } else if (value === "5f2d73fdf26a6d77e0c640e4") {
      this.state.activeTab = 0;
    } else if (value === "5f2d7405f26a6d77e0c640e5") {
      this.state.activeTab = 1;
    }

    if (indexO === -1) {
      this.state.categories.push(value);
      console.log(this.state.categories.length);
      if (this.state.categories.length === 1) {
        if (this.state.categories.includes("5f3cf4ee250f7a7e4d8d0ae0")) {
          this.state.activeTab = 1;
        } else if (this.state.categories.includes("5f2d73fdf26a6d77e0c640e4")) {
          this.state.activeTab = 0;
        } else if (this.state.categories.includes("5f2d7405f26a6d77e0c640e5")) {
          this.state.activeTab = 1;
        }
      }
    } else {
      this.state.categories.splice(indexO, 1);
      if (this.state.categories.length === 1) {
        if (this.state.categories.includes("5f3cf4ee250f7a7e4d8d0ae0")) {
          this.state.activeTab = 1;
        } else if (this.state.categories.includes("5f2d73fdf26a6d77e0c640e4")) {
          this.state.activeTab = 0;
        } else if (this.state.categories.includes("5f2d7405f26a6d77e0c640e5")) {
          this.state.activeTab = 1;
        }
      }
    }
    this.setState({ ...this.state });
  };

  applyCatFilter = (type) => {
    document.getElementById("refetchCat").click();
    if (type === 1) {
      this.enableCategoryFilter();
    } else {
      this.enableMoreFilter();
    }
  };
  selectBusiness = (value) => {
    const index = this.state.chooseItems.indexOf(value);
    if (index === -1) {
      this.state.chooseItems.push(value);
    } else {
      this.state.chooseItems.splice(index, 1);
    }
    this.setState({ ...this.state });
  };
  handleChangeMoreFilter = (value, name, path) => {
    this.setState({
      [name]: value === null ? [] : value,
    });
    // , () => {
    //     if (document.getElementById(path) !== '' &&  value.length !== 0 && (path !== null || path !== '')) {
    //         // console.log(value)
    //         // document.getElementById(path).click();
    //     }
    // })
  };
  // callValue = (data) => {
  //     // console.log(data);

  //     if (this.state.isFirstRender) {
  //         data && data.categories.map((list) => {
  //             if (list.name === "Material Suppliers" || list.name === "Manufacturers" || list.name === "Manufacturer with Material") {
  //                 this.state.categories.push(list.id)
  //             }
  //         })
  //         this.setState({ isFirstRender: false })
  //     }
  // }
  render() {
    return (
      <>
        <main id="main-content" tabIndex="-1" role="main">
          {/* <Query query={GET_CATEGORY} context={{ useMultipart: true }}>
                    {({ loading, data }) => !loading && (
                        <div>
                            {this.callValue(data)}
                        </div>
                    )}
                </Query> */}
          <div className="header-filter">
            <div className="header-filter-container">
              <div className="custom-container">
                <div className="category-con">
                  <button
                    onClick={() => this.enableCategoryFilter()}
                    className="category-btn"
                  >
                    <img src="/images/category.svg" alt="" /> Category
                </button>
                  {/* <div className="category-dropdown show"> */}
                  {this.state.categoryFilter && (
                    <div className="category-dropdown show">
                      <Query query={GET_CATEGORY}>
                        {({ loading, data }) =>
                          loading ? (
                            <div>Loading...</div>
                          ) : (
                              <div>
                                <ul>
                                  {data.categories.map((list, key) => {
                                    if (
                                      list.name === "Material Suppliers" ||
                                      list.name === "Manufacturers" ||
                                      list.name === "Manufacturer with Material"
                                    ) {
                                      return (
                                        <li>
                                          <button>
                                            <span>
                                              <img
                                                src="/images/category.svg"
                                                alt=""
                                              />{" "}
                                              {list.name}
                                            </span>
                                            <label className="checkbox-container">
                                              <input
                                                type="checkbox"
                                                id={list.id}
                                                name={list.name}
                                                checked={
                                                  this.state.categories.indexOf(
                                                    list.id
                                                  ) === -1
                                                    ? false
                                                    : true
                                                }
                                                onChange={(e) =>
                                                  this.handleFilterCategory(
                                                    e.target.value
                                                  )
                                                }
                                                value={list.id}
                                              />
                                              <span className="checkmark"></span>
                                            </label>
                                          </button>
                                        </li>
                                      );
                                    }
                                  })}
                                </ul>
                                <div className="category-submit">
                                  <button
                                    className="btn"
                                    onClick={() => this.applyCatFilter(1)}
                                  >
                                    APPLY
                              </button>
                                </div>
                              </div>
                            )
                        }
                      </Query>
                    </div>
                  )}
                </div>
                <div className="filter-con">
                  <button
                    onClick={() => this.enableMoreFilter()}
                    className="category-btn"
                  >
                    <img src="/images/filter.svg" alt="" /> More Filter
                </button>
                </div>
                <div className="reset-con">
                  <button
                    onClick={() => window.location.reload()}
                    className="category-btn reset"
                  >
                    <img src="/images/reset.svg" alt="" /> Reset
                </button>
                </div>
              </div>
            </div>
          </div>
          {
            <div
              className="more-filter-container"
              style={{ display: this.state.moreFilter ? "block" : "none" }}
            >
              <div className="custom-container">
                <Query query={GET_CATEGORY}>
                  {({ loading, data }) =>
                    loading ? (
                      <div className="loader-div">
                        <img src="/images/loader.gif" alt="" />
                      </div>
                    ) : (
                        <div>
                          <ul className="tabs-ul">
                            {data &&
                              data.categories.map((list, key) => {
                                if (
                                  list.name === "Material Suppliers" ||
                                  list.name === "Manufacturers"
                                ) {
                                  // console.log(this.state.categories)

                                  if (
                                    this.state.categories.includes(list.id) ||
                                    (this.state.categories.includes(
                                      "5f3cf4ee250f7a7e4d8d0ae0"
                                    ) &&
                                      list.name === "Manufacturers")
                                  ) {
                                    return (
                                      <li key={key}>
                                        <button
                                          className={
                                            this.state.activeTab === key
                                              ? "tab1 active"
                                              : "tab1"
                                          }
                                          onClick={() => this.enableTabActive(key)}
                                        >
                                          {list.name}
                                          {/* <label className="checkbox-container"> {list.name}
                                                                                <input type="checkbox"  />
                                                                                <span className="checkmark"></span>
                                                                            </label> */}
                                        </button>
                                      </li>
                                    );
                                  }
                                }
                              })}
                          </ul>
                        </div>
                      )
                  }
                </Query>

                <hr className="tab-hr" />

                {this.state.categories.length !== 0 ? (
                  <div
                    className="tab-content"
                    style={{
                      display: this.state.activeTab === 0 ? "block" : "none",
                    }}
                  >
                    <div className="floating-form__field floating-form__field_multiselect">
                      <Query query={GET_MATERIALTYPE}>
                        {({ loading, data }) =>
                          !loading &&
                          data &&
                          data.materialTypes && (
                            <Select
                              isMulti
                              name="colors"
                              options={data.materialTypes.map((list) => {
                                return {
                                  label: list.name,
                                  value: list.id,
                                };
                              })}
                              placeholder="Select"
                              onChange={(e) =>
                                this.handleChangeMoreFilter(
                                  e,
                                  "material_types",
                                  "materialTypeId"
                                )
                              }
                              className="field"
                              classNamePrefix="select"
                            />
                          )
                        }
                      </Query>
                      <label className="input-label1">Material Type</label>
                    </div>
                    {this.state.material_types.length !== 0 && (
                      <div className="floating-form__field floating-form__field_multiselect">
                        <Query
                          query={GET_GRADEBY_TYPE_NEW}
                          variables={{
                            id: this.state.material_types.map((list) => {
                              return list.value;
                            }),
                          }}
                        >
                          {({ loading, data, refetch }) => {
                            var materialGrades = [];
                            !loading &&
                              data.materialTypes &&
                              data.materialTypes.forEach((list) => {
                                list.material_grades.forEach((item) => {
                                  materialGrades.push(item);
                                });
                              });
                            return (
                              <div>
                                {!loading && (
                                  <>
                                    <Select
                                      isMulti
                                      placeholder="Select"
                                      name="colors"
                                      options={materialGrades.map((list) => {
                                        return {
                                          label: list.name,
                                          value: list.id,
                                        };
                                      })}
                                      onChange={(e) =>
                                        this.handleChangeMoreFilter(
                                          e,
                                          "material_grades",
                                          "materialGradeId"
                                        )
                                      }
                                      className="field"
                                      classNamePrefix="select"
                                    />
                                    <label className="input-label1">
                                      Material Grade
                                  </label>
                                  </>
                                )}
                                <button
                                  hidden
                                  id="materialTypeId"
                                  onClick={() => refetch()}
                                ></button>
                              </div>
                            );
                          }}
                        </Query>
                      </div>
                    )}
                    {this.state.material_grades.length !== 0 && (
                      <div className="floating-form__field floating-form__field_multiselect">
                        <Query
                          query={GET_SHAPEBY_TYPE_NEW}
                          variables={{
                            id: this.state.material_grades.map((list) => {
                              return list.value;
                            }),
                          }}
                        >
                          {({ loading, data, refetch }) => {
                            var materialShapes = [];
                            !loading &&
                              data.materialGrades.forEach((list) => {
                                list.material_shapes.forEach((item) => {
                                  materialShapes.push(item);
                                });
                              });
                            return (
                              <div>
                                {!loading && (
                                  <>
                                    <Select
                                      isMulti
                                      name="colors"
                                      placeholder="Select"
                                      options={materialShapes.map((list) => {
                                        return {
                                          label: list.name,
                                          value: list.id,
                                        };
                                      })}
                                      onChange={(e) =>
                                        this.handleChangeMoreFilter(
                                          e,
                                          "material_shapes",
                                          ""
                                        )
                                      }
                                      className="field"
                                      classNamePrefix="select"
                                    />
                                    <label className="input-label1">
                                      Material Shapes
                                  </label>
                                  </>
                                )}
                                <button
                                  hidden
                                  id="materialGradeId"
                                  onClick={() => refetch()}
                                ></button>
                              </div>
                            );
                          }}
                        </Query>
                      </div>
                    )}
                  </div>
                ) : (
                    "Please select category to view more filter options "
                  )}

                {this.state.categories.length !== 0 ? (
                  <div
                    className="tab-content"
                    style={{
                      display: this.state.activeTab === 1 ? "block" : "none",
                    }}
                  >
                    <div className="floating-form__field floating-form__field_multiselect">
                      <Query query={GET_MANUFACTURING_PROCESS}>
                        {({ loading, data }) => {
                          return (
                            <div>
                              {!loading && data && data.manufacturingProcesses && (
                                <>
                                  <Select
                                    isMulti
                                    name="colors"
                                    placeholder="Select"
                                    options={data.manufacturingProcesses.map(
                                      (list) => {
                                        return {
                                          label: list.name,
                                          value: list.id,
                                        };
                                      }
                                    )}
                                    onChange={(e) =>
                                      this.handleChangeMoreFilter(
                                        e,
                                        "manufacturingProcessesId",
                                        "manufactureTypeId"
                                      )
                                    }
                                    className="field"
                                    classNamePrefix="select"
                                  />
                                  <label className="input-label1">
                                    Process Type
                                </label>
                                </>
                              )}
                            </div>
                          );
                        }}
                      </Query>
                    </div>
                    {this.state.manufacturingProcessesId.length !== 0 && (
                      <div className="floating-form__field floating-form__field_multiselect">
                        <Query
                          query={GET_MANUFACTURING_OPERATION}
                          variables={{
                            id: this.state.manufacturingProcessesId.map(
                              (list) => {
                                return list.value;
                              }
                            ),
                          }}
                        >
                          {({ loading, data, refetch }) => {
                            var manufacturingOperations = [];
                            !loading &&
                              data.manufacturingProcesses.forEach((list) => {
                                list.manufacturing_operations.forEach((item) => {
                                  manufacturingOperations.push(item);
                                });
                              });
                            return (
                              <div>
                                {!loading && (
                                  <>
                                    <Select
                                      isMulti
                                      name="colors"
                                      options={manufacturingOperations.map(
                                        (list) => {
                                          return {
                                            label: list.name,
                                            value: list.id,
                                          };
                                        }
                                      )}
                                      placeholder="Select"
                                      onChange={(e) =>
                                        this.handleChangeMoreFilter(
                                          e,
                                          "manufacturingOperationsId",
                                          "manufactureOperationFetchId"
                                        )
                                      }
                                      className="field"
                                      classNamePrefix="select"
                                    />
                                    <label className="input-label1">
                                      Operation
                                  </label>
                                  </>
                                )}
                                <button
                                  hidden
                                  id="manufactureTypeId"
                                  onClick={() => refetch()}
                                ></button>
                              </div>
                            );
                          }}
                        </Query>
                      </div>
                    )}
                    {this.state.manufacturingOperationsId.length !== 0 && (
                      <div className="floating-form__field floating-form__field_multiselect">
                        <Query
                          query={GET_OPERATIONJOB_TYPE_NEW}
                          variables={{
                            id: this.state.manufacturingOperationsId.map(
                              (list) => {
                                return list.value;
                              }
                            ),
                          }}
                        >
                          {({ loading, data, refetch }) => {
                            var manufacturingJobSize = [];
                            !loading &&
                              data.manufacturingOperations.forEach((list) => {
                                list.manufacturing_job_sizes.forEach((item) => {
                                  manufacturingJobSize.push(item);
                                });
                              });
                            return (
                              <div>
                                {!loading && (
                                  <>
                                    <Select
                                      isMulti
                                      name="colors"
                                      options={manufacturingJobSize.map(
                                        (list) => {
                                          return {
                                            label: list.name,
                                            value: list.id,
                                          };
                                        }
                                      )}
                                      placeholder="Select"
                                      onChange={(e) =>
                                        this.handleSelectSingle(
                                          e,
                                          "manufacturingJobSizeId",
                                          ""
                                        )
                                      }
                                      className="field"
                                      classNamePrefix="select"
                                    />
                                    <label className="input-label1">
                                      Job Size
                                  </label>
                                  </>
                                )}
                                <button
                                  hidden
                                  id="manufactureOperationFetchId"
                                  onClick={() => refetch()}
                                ></button>
                              </div>
                            );
                          }}
                        </Query>
                      </div>
                    )}
                  </div>
                ) : (
                    ""
                  )}

                {/*
                            <div className="tab-content" style={{ display: (this.state.activeTab === 2) ? "block" : "none" }}>
                                <div className="floating-form__field floating-form__field_select">
                                    <Query query={GET_TESTING} >
                                        {({ loading, data }) => !loading && (
                                            <select className="field">
                                                <option>Select Testing Types</option>
                                                {data.testingTypes.map((list, key) => (
                                                    <option key={key} value={list.id}>{list.name}</option>
                                                ))}
                                            </select>
                                        )}
                                    </Query>
                                </div>
                                <div className="floating-form__field floating-form__field_select">
                                    <Query query={GET_TESTING} >
                                        {({ loading, data }) => !loading && (
                                            <select className="field">
                                                <option>Select Testing Operation</option>
                                                {data.testingOperations.map((list, key) => (
                                                    <option key={key} value={list.id}>{list.name}</option>
                                                ))}
                                            </select>
                                        )}
                                    </Query>
                                </div>
                            </div>
                            <div className="tab-content" style={{ display: (this.state.activeTab === 3) ? "block" : "none" }}>
                                <div className="floating-form__field floating-form__field_select">
                                    <Query query={GET_SOLUTION_PROVIDER} >
                                        {({ loading, data }) => !loading && (
                                            <select className="field">
                                                <option>Select Provider Types</option>
                                                {data.solutionProviderTypes.map((list, key) => (
                                                    <option key={key} value={list.id}>{list.name}</option>
                                                ))}
                                            </select>
                                        )}
                                    </Query>
                                </div>
                                <div className="floating-form__field floating-form__field_select">
                                    <Query query={GET_SOLUTION_PROVIDER} >
                                        {({ loading, data }) => !loading && (
                                            <select className="field">
                                                <option>Select Provider Industries</option>
                                                {data.solutionProviderIndustries.map((list, key) => (
                                                    <option key={key} value={list.id}>{list.name}</option>
                                                ))}
                                            </select>
                                        )}
                                    </Query>
                                </div>
                                <div className="floating-form__field floating-form__field_select">
                                    <Query query={GET_SOLUTION_PROVIDER} >
                                        {({ loading, data }) => !loading && (
                                            <select className="field">
                                                <option>Select Provider Services</option>
                                                {data.solutionProviderServices.map((list, key) => (
                                                    <option key={key} value={list.id}>{list.name}</option>
                                                ))}
                                            </select>
                                        )}
                                    </Query>
                                </div>
                            </div>
                            <div className="tab-content" style={{ display: (this.state.activeTab === 4) ? "block" : "none" }}>
                                <div className="floating-form__field floating-form__field_select">
                                    <Query query={GET_RESOURCES} >
                                        {({ loading, data }) => !loading && (
                                            <select className="field">
                                                <option>Select Type</option>
                                                {data.resourceTypes.map((list, key) => (
                                                    <option key={key} value={list.id}>{list.name}</option>
                                                ))}
                                            </select>
                                        )}
                                    </Query>
                                </div>
                                <div className="floating-form__field floating-form__field_select">
                                    <Query query={GET_RESOURCES} >
                                        {({ loading, data }) => !loading && (
                                            <select className="field">
                                                <option>Select Industries</option>
                                                {data.resourceIndustries.map((list, key) => (
                                                    <option key={key} value={list.id}>{list.name}</option>
                                                ))}
                                            </select>
                                        )}
                                    </Query>
                                </div>
                                <div className="floating-form__field floating-form__field_select">
                                    <Query query={GET_RESOURCES} >
                                        {({ loading, data }) => !loading && (
                                            <select className="field">
                                                <option>Select Competencies</option>
                                                {data.resourceCompetencies.map((list, key) => (
                                                    <option key={key} value={list.id}>{list.name}</option>
                                                ))}
                                            </select>
                                        )}
                                    </Query>
                                </div>
                            </div>
                            */}
                {this.state.categories.length !== 0 ? (
                  <div className="tab-btn">
                    <button onClick={() => this.applyCatFilter()} className="btn">
                      APPLY
                  </button>
                  </div>
                ) : (
                    ""
                  )}
              </div>
            </div>
          }
          <div className="hero --no-padding-bottom">
            <div className="hero__inner cat-padding">
              <h1
                style={{ textAlign: "center" }}
              // className="hero__heading heading-xl u-text-align-center js-underline-container js-toggle-on-scroll --is-in-view custom-page-heading"
              >
                Currently serving in Bengaluru and coming to other cities soon{" "}
              </h1>
              <div className="submit-req">
                <button
                  type="submit"
                  className="btn"
                  onClick={() => this.handleSubmit()}
                >
                  Submit Requirement
              </button>
                <p id="err-msg" className="err-msg-21"></p>
              </div>
            </div>
          </div>
          <div className="custom-container custom-container-padding">
            <div>
              {!this.state.refetch && (
                <Query
                  query={GET_BUSINESSPOSTS}
                  variables={{
                    categories: this.state.categories,
                    material_types: this.state.material_types.map((item) => {
                      return item.value;
                    }),
                    material_grades: this.state.material_grades.map((item) => {
                      return item.value;
                    }),
                    material_shapes: this.state.material_shapes.map((item) => {
                      return item.value;
                    }),
                    manufacturing_processes: this.state.manufacturingProcessesId.map(
                      (item) => {
                        return item.value;
                      }
                    ),
                    manufacturing_operations: this.state.manufacturingOperationsId.map(
                      (item) => {
                        return item.value;
                      }
                    ),
                    manufacturing_job_sizes: this.state.manufacturingJobSizeId
                      ?.value
                      ? this.state.manufacturingJobSizeId.value
                      : [],
                  }}
                >
                  {({ loading, data, refetch }) =>
                    !loading && (
                      <div className="custom-row">
                        {data.filterBusinesses.length === 0 && (
                          <div style={{ textAlign: "center", width: "100%" }}>
                            Let us help you with your requirement,{" "}
                            <a style={{ color: "blue" }} href="/contact">
                              share details with us
                          </a>
                          </div>
                        )}
                        {data &&
                          data.filterBusinesses.map((post) => (
                            <div className="custom-col-3">
                              <BusinessCard
                                selectBusiness={this.selectBusiness}
                                post={post}
                                selectedItems={this.state.chooseItems}
                              />
                            </div>
                          ))}
                        <button hidden id="refetchCat" onClick={() => refetch()}>
                          Refetch!
                      </button>
                      </div>
                    )
                  }
                </Query>
              )}
            </div>
            {/* <div className="custom-row py-3" style={{ width: "100%" }}>
            <div className="custom-col-3">
              <div className="pagination">
                <button>&laquo;</button>
                <button className="active">1</button>
                <button>2</button>
                <button>3</button>
                <button>&raquo;</button>
              </div>
            </div>
            <div className="custom-col-3"></div>
            <div className="custom-col-3">
              <button type="submit" className="btn show-more-btn">
                show me more
              </button>
            </div>
          </div>
         */}
          </div>
        </main>
        <div className={"submit-fixed " + (this.state.headerScrolled ? "is-fixed" : "")}>
          <div className="submit-req">
            <button
              type="submit"
              className="btn"
              onClick={() => this.handleSubmit()}
            >
              Submit Requirement
              </button>
              <p id="err-msg12"></p>
          </div>
        </div>
      </>
    );
  }
}

// export default WithCategory(MainPages);
export default MainPages;

//main page
