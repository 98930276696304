import React from "react";
import MSMECard from "./common/MSME-card";
import { Query } from "react-apollo";
import { GET_MSME_NEWS_LIST } from "./query";

class MSMEPages extends React.Component {
  render() {
    return (
      <main id="main-content" tabIndex="-1" role="main">
        <div className="hero --no-padding-bottom">
          <div className="hero__inner">
            <h1
              style={{ textAlign: "center" }}
              className="hero__heading heading-xl u-text-align-center js-underline-container js-toggle-on-scroll --is-in-view mx-wid"
            >
              Our Latest Updates and Stories
            </h1>
          </div>
        </div>
        <div className="custom-container custom-container-padding">
          <Query query={GET_MSME_NEWS_LIST}>
            {({ loading, data, refetch }) =>
              !loading && (
                <div className="custom-row">
                  {data.articles.length === 0 && (
                    <div style={{ textAlign: "center", width: "100%" }}>
                      No records found!
                    </div>
                  )}
                  {data &&
                    data.articles &&
                    data.articles.map((post) => (
                      <div className="custom-col-3" key={post._id}>
                        <MSMECard news={post} />
                      </div>
                    ))}
                  <button hidden id="refetchCat" onClick={() => refetch()}>
                    Refetch!
                  </button>
                </div>
              )
            }
          </Query>
          <div className="custom-row py-3" style={{ width: "100%" }}>
            <div className="custom-col-3">
              <div className="pagination">
                <button>&laquo;</button>
                <button className="active">1</button>
                <button>2</button>
                <button>3</button>
                <button>&raquo;</button>
              </div>
            </div>
            <div className="custom-col-3"></div>
            <div className="custom-col-3">
              <button type="submit" className="btn show-more-btn">
                show me more
              </button>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

// export default WithCategory(MainPages);
export default MSMEPages;

//main page
