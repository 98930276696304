import React from "react";
import { NavLink } from "react-router-dom";
import gql from "graphql-tag";
import { Query } from "react-apollo";
// import MarkdownView from "react-showdown";

export const GET_SPECIFIC_NEWS = gql`
  query article($id: ID!) {
    article(id: $id) {
      title
      subject
      cover_image {
        _id
        url
      }
      content
      categories {
        name
      }
    }
  }
`;

class MsmeDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.match.params.Id,
    };
    // localStorage.clear();
  }
  render() {

    return (
      <main id="main-content" tabIndex="-1" role="main">
        <div style={{ paddingTop: "55px" }}>
          <Query query={GET_SPECIFIC_NEWS} variables={{ id: this.state.id }}>
            {({ loading, data }) =>
              !loading ? (
                <div className="custom-container custom-container-padding">
                  <div>
                    <NavLink to="/msme-list" className="go-back-static">
                      <img src="/images/arrow_back.svg" alt="" />
                      Go Back
                    </NavLink>
                  </div>

                  <>
                    <div className="half-div-500">
                      <h5 className="msme-subheading">
                        {data.article.subject}
                      </h5>
                      <h4 className="msme-heading">{data.article.title}</h4>
                      {/* <h5 className="msme-date">Published Aug 20, 2020</h5> */}
                    </div>
                    <div className="clearfix"></div>

                    <center>
                      {" "}
                      <img
                        src={
                            data.article.cover_image
                            ? `${data.article.cover_image.url}`
                            : "/images/banner.png"
                        }
                        alt=""
                        style={{ maxWidth: "100%", maxHeight: "100%" }}
                      />
                    </center>

                    <div className="msme-para">
                      <p>{data.article.content}</p>
                    </div>
                  </>
                </div>
              ) : (
                <div className="loader-div">
                  <img src="/images/loader.gif" alt="" />
                </div>
              )
            }
          </Query>
        </div>
      </main>
    );
  }
}
export default MsmeDetails;
