import gql from 'graphql-tag';

export const GET_MATERIALTYPE = gql`
query {
  materialTypes{
    name
    id
  }
}`;
export const GET_MATERIALGRADE = gql`
query {
  materialGrades{
    name
    id
}
}`;
export const GET_MATERIAL_SHAPE = gql`
query {
    materialShapes{
    name
    id
}
}`;
export const GET_MANUFACTURING_PROCESS = gql`
query {
    manufacturingProcesses{
    name
    id
}
}`;
export const GET_MANUFACTURING_OPERATION = gql`
query manufacturingProcessesByID($id:[ID]){
  manufacturingProcesses(where:{id:$id}){
    name
    id
    manufacturing_operations{
      name
      id
    }
}
}`;

// UPDATED
export const GET_MANUFACTURING_FINISHING_TYPE = gql`
query finishingTypes($id:[ID]){
  finishingTypes(where:{id:$id}){
    name
    id
    finishing_colors{
      name
      id
    }
}
}`;


export const GET_MANUFACTURING_FINISHING_COLOR = gql`
query finishingTypes($id:[ID]){
  finishingTypes(where:{id:$id}){
    name
    id
    finishing_colors{
      name
      id
    }
}
}`;
export const GET_TESTING = gql`
query {
    testingOperations{
        name
        id
    }
    testingFors{
      name
      id
    }
    testingTypes{
        name
        id
  }
}`;
export const NEWQUERY = gql`
mutation createQuoteFormDatum($data:QuoteFormDatumInput!){
  createQuoteFormDatum(input:{data:$data}){
    quoteFormDatum{
      name
    }
  }
}`


export const CONTACTS_SUBMIT = gql`
mutation createContactFormDatum($data:ContactFormDatumInput!){
  createContactFormDatum(input:{data:$data}){
    contactFormDatum{
      business_name
      business_email
      business_phone
    }
  }
}`


export const GET_TESTING_FORBY_ID = gql`
query testingFors($id:ID!){
  testingFors(where:{id:$id}){
    name
    id
    testing_operations{
      name
      id
    }
  }
}`;

export const GET_SOLUTION_PROVIDER = gql`
query {
    solutionProviderServices{
        name
        id
    }
    solutionProviderIndustries{
        name
        id
    }
    solutionProviderTypes{
        name
        id
    }
}
`
export const GET_SOLUTION_PROVIDER_FORBY_ID = gql`
query solutionIndustry($id:ID!){
  solutionProviderIndustries(where:{id:$id}){
    name
    id
    solution_provider_services{
      name
      id
    }
  }
}`;
export const GET_SOLUTION_INSD_ID_TYPE = gql`
query solutionType($id:ID!){
  solutionProviderServices(where:{id:$id}){
    name
    id
    solution_provider_types{
      name
      id
    }
  }
}
`;
export const GET_RESOURCES = gql`
query {
  resourceTypes{
    name
    id
  }
  resourceCompetencies{
    name
    id
  }
  resourceIndustries{
    name
    id
  }
  
}
`
export const GET_RESOURCE_PROVIDER_FORBY_ID = gql`
query resourceIndustry($id:ID!){
  resourceIndustries(where:{id:$id}){
    name
    id
    resource_competencies{
      name
      id
    }
  }
}`;
export const GET_RESOURCE_INSD_ID_TYPE = gql`
query resourceType($id:ID!){
  resourceCompetencies(where:{id:$id}){
    name
    id
    resource_types{
      name
      id
    }
  }
}
`;

export const GET_ONEPOSTS = gql`
  query businesses($id:ID!){
      businesses(where:{id:$id}){
          name
          about
          categories{
             name 
             id
            }
          address{
            city{
              name
            }
            address_line_1
            address_line_2
            state {
              name
            }
            pincode
        } 
      }
  }
`;
export const GET_BUSINESSPOSTS = gql`
query filterBusinesses($limit:Int,$start:Int,$categories:[ID],$material_types:[ID],$material_shapes:[ID],$material_grades:[ID],$manufacturing_processes:[ID],$manufacturing_operations:[ID],$manufacturing_job_sizes:[ID])
{
  filterBusinesses(limit:$limit,start:$start,filters:{categories:$categories,material_grades:$material_grades,material_shapes:$material_shapes,material_types:$material_types,
    manufacturing_processes:$manufacturing_processes,manufacturing_operations:$manufacturing_operations,manufacturing_job_sizes:$manufacturing_job_sizes
  }){
    about
    name
    id
    categories{
        name
        id
      }
    logo{
      url
    }  
    address{
      address_line_1 
      city {
        name
      }
    } 
  }
}
`;
export const GET_POSTS = gql`
query 
{businesses{
  about
  name
  id
   categories{
      name
      id
    }
  logo{
    previewUrl
  } 
  address{
    address_line_1 
    city {
      name
    }
  } 
}
}
`;
export const GET_CATEGORY = gql`
query {
  categories{
    name
    id
  }
}
`;
export const GET_GRADEBY_TYPE = gql`
query 
  materialTypes($id:ID!){
    materialTypes(where:{id:$id}){
  name
  material_grades{
    name
    id
  }
}
}
`;
export const GET_GRADEBY_TYPE_NEW = gql`
query 
  materialTypes($id:[ID]){
    materialTypes(where:{id_in:$id}){
  name
  material_grades{
    name
    id
  }
}
}
`;
export const GET_SHAPEBY_TYPE_NEW = gql`
query 
materialGrades($id:[ID]){
  materialGrades(where:{id_in:$id}){
  name
  material_shapes{
    name
    id
  }
}
}
`;
export const GET_OPERATIONJOB_TYPE_NEW = gql`
query 
manufacturingOperations($id:[ID])
{
  manufacturingOperations(where:{id:$id}){
		name
    manufacturing_job_sizes{
      name
      id
    }
  
  }
}`


export const GET_DIMENSION_TYPE_NEW = gql` query
  materialShape($id: ID!){
  materialShapes(where:{id:$id}){ 
    material_dimensions{
      name
    }
  }
}`
export const GET_TESTING_OPERATION_ID_TYPE = gql`
query 
  testingTypes($id:ID!){
    testingOperations(where:{id:$id}){
  name
  testing_types{
    name
    id
  }
}
}
`




// for MSME
export const GET_MSME_NEWS_LIST = gql`
query {
  articles{
    _id
    title
    categories{
      name
    }
    subject
    cover_image{
      url
    }
    content
  }
}`



