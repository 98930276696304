import React from "react";
import { NavLink } from "react-router-dom";

function MSMECard(props) {
  const { news } = props;

  return (
    <div className="msme-card">
      <NavLink to={`/msme-details/${news._id}`}>
        <h4 className="msme-card-heading">{news.title}</h4>
      {/* </NavLink>
      <NavLink to={`/msme-details/${news._id}`}> */}
        <div>
          {/* <div className="msme-card-image" style={{ backgroundImage: "url('/images/1.png')" }}></div> */}
          <div
            className="msme-card-image"
            style={{
              backgroundImage: news.cover_image
                ? `url(${news.cover_image.url})`
                : "url('/images/1.png')",
            }}
          ></div>
        </div>
      </NavLink>
      <h5 className="msme-card-subheading">{news.subject}</h5>
    </div>
  );
}

export default MSMECard;
