import React, { Component } from "react";
import SimpleReactValidator from "simple-react-validator";
import { CONTACTS_SUBMIT } from "./query";
import { Mutation } from "react-apollo";
import { toast } from "react-toastify";

class ContactPage extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();
    this.state = {
      businessName: "",
      businessEmail: "",
      businessPhone: "",
      comments: "",
      contactPerson: "",
      attachment: "",
      attachments:{
        attachment:""
      },
      isUploading: false,
      request_type: "",
    };
  }
  //

  handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({ [name]: value });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.validator.allValid()) {
      // alert('asdfsadf')
    } else {
      this.validator.showMessages();
      window.scrollTo({ top: 0, behavior: "smooth" });
      this.forceUpdate();
    }
  };
  radioStatus = (e) => {
    e.preventDefault();
    this.setState({ request_type: e.target.value });
  };

  handleAttachment = async (e, name) => {
    const data = new FormData();
    if (
      e.target.files[0].type !==
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      data.append("files", e.target.files[0]);
      this.state.attachments[name] = e.target.files[0];
      this.setState({
        isUploading: true,
      });
      fetch("https://backend.jobcards.in/upload", {
        method: "POST",
        body: data,
      })
        .then((response) => response.json())
        .then((resData) => {
          this.state.attachment = resData[0]._id;
          this.setState({
            isUploading: false,
          });
        });
    } else {
      toast.error("Recommended - Zip Files");
    }
  };

  removeAttachment(name) {
    document.getElementById(name).value = "";
    this.state.attachment = "";
    this.state.attachment = "";
    this.setState({
      ...this.state,
    });
  }
  render() {
    this.validator.purgeFields();
    return (
      <main id="main-content" tabindex="-1" role="main">
        <div className="hero --no-padding-bottom">
          <div className="hero__inner">
            <h1 className="hero__heading heading-xl u-text-align-center js-underline-container js-toggle-on-scroll --is-in-view mx-wid">
              Meet your business challenge head on.
            </h1>
            <div className="hero__form">
              <div className="floating-form">
                <h2 className="floating-form__header body-l">
                  <p>
                    Have a question about JobCards or Partners? Fill out the
                    form, tell us about your project, and a JobCards expert will
                    help you find the right solution.
                  </p>
                </h2>
                <h3 className="floating-form__sub-heading">
                  Ready to talk to us?
                </h3>

                <div className="floating-form --one-col">
                  <div className="form-div" style={{ marginTop: "0px" }}>
                    <div className="floating-form__form">
                      <div className="floating-form__field">
                        <input
                          id="text_cname"
                          className="field"
                          placeholder="&nbsp;"
                          onChange={(e) => this.handleChange(e)}
                          name="businessName"
                        />
                        <label className="input-label1" for="text_cname">
                          Company Name<span>*</span>
                        </label>
                        {this.validator.message(
                          "businessName",
                          this.state.businessName,
                          "required"
                        )}
                      </div>
                      <div className="floating-form__field">
                        <input
                          id="text_name"
                          className="field"
                          placeholder="&nbsp;"
                          onChange={(e) => this.handleChange(e)}
                          name="contactPerson"
                        />
                        <label className="input-label1" for="text_name">
                          Name<span>*</span>
                        </label>
                        {this.validator.message(
                          "contactPerson",
                          this.state.contactPerson,
                          "required"
                        )}
                      </div>
                      <div className="floating-form__field">
                        <input
                          name="businessEmail"
                          id="text_email"
                          className="field"
                          placeholder="&nbsp;"
                          onChange={(e) => this.handleChange(e)}
                        />
                        <label className="input-label1" for="text_email">
                          Email<span>*</span>
                        </label>
                        {this.validator.message(
                          "businessEmail",
                          this.state.businessEmail,
                          "required"
                        )}
                      </div>
                      <div className="floating-form__field">
                        <input
                          name="businessPhone"
                          id="c_size"
                          className="field"
                          placeholder="&nbsp;"
                          onChange={(e) => this.handleChange(e)}
                        />
                        <label className="input-label1" for="c_size">
                          Phone Number<span>*</span>
                        </label>
                        {this.validator.message(
                          "businessPhone",
                          this.state.businessPhone,
                          "required"
                        )}
                      </div>
                      <div className="floating-form__field">
                        <grammarly-extension
                          style={{
                            position: "absolute",
                            top: "0px",
                            left: "0px",
                            pointerEvents: "none",
                          }}
                          className="_1KJtL"
                        ></grammarly-extension>
                        <textarea
                          name="comments"
                          className="field --textarea"
                          rows="4"
                          placeholder="&nbsp;"
                          id="text_comm"
                          spellcheck="false"
                          onChange={(e) => this.handleChange(e)}
                        ></textarea>
                        <label className="input-label1" for="text_comm">
                          Comments
                        </label>
                        {/* {this.validator.message('comments', this.state.comments, 'required')} */}
                      </div>

                      <div class="half-div half-div-half">
                        <div class="form-radio">
                          <label class="form-check-label">
                            <input
                              type="radio"
                              name="radio"
                              value="Enquiry"
                              onChange={(e) => {
                                this.setState({ request_type: e.target.value });
                              }}
                            />
                            Enquiry
                          </label>
                        </div>
                      </div>
                      <div class="half-div half-div-half">
                        <div class="form-radio">
                          <label class="form-check-label">
                            <input
                              type="radio"
                              name="radio"
                              value="Registration"
                              onChange={(e) => {
                                this.setState({ request_type: e.target.value });
                              }}
                            />
                            Registration
                          </label>
                        </div>
                      </div>
                      <div className="clearfix"></div>
                    </div>

                    <div className="floating-form__field">
                      <div className="attachment-input">
                        <input
                          type="file"
                          id="material_requirement"
                          onChange={(e) =>
                            this.handleAttachment(e, "attachment")
                          }
                          name="attachment"
                        />
                        <p>
                          <img src="/images/attachment.svg" alt="" /> Attach
                          Documents
                        </p>
                      </div>
                      {this.state.attachments.attachment.name ? (
                        <div className="attachment-remove">
                          <p>{this.state.attachments.attachment.name}</p>
                          <button
                            onClick={() => this.removeAttachment("attachment")}
                          >
                            &times;
                          </button>
                        </div>
                      ) : (
                        <p style={{ fontSize: "13px", color: "gray" }}>
                          Recommended - Zip Files
                        </p>
                      )}
                      {/* {this.validator.message('attachment', this.state.request.material_requirement.attachment, 'required')} */}
                    </div>
                    {this.validator.allValid() ? (
                      <Mutation
                        mutation={CONTACTS_SUBMIT}
                        variables={{
                          data: {
                            business_name: this.state.businessName,
                            business_email: this.state.businessEmail,
                            business_phone: this.state.businessPhone,
                            contact_person: this.state.contactPerson,
                            comments: this.state.comments,
                            attachment: this.state.attachment,
                            request_type: this.state.request_type,
                          },
                        }}
                      >
                        {(postMutation, { data }) => {
                          if (data) {
                            toast.success("Request submitted successfully");
                            this.props.history.push("/");
                          }
                          return (
                            <button
                              className="btn btn-submit-now"
                              onClick={postMutation}
                            >
                              Submit
                            </button>
                          );
                        }}
                      </Mutation>
                    ) : this.state.isUploading ? (
                      <button className="btn btn-submit-now">
                        File Upload Processing...
                      </button>
                    ) : (
                      <button
                        onClick={(e) => this.handleSubmit(e)}
                        className="btn btn-submit-now"
                      >
                        Submit now
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="l-container --extra-narrow"
          style={{ padding: "10px", marginTop: "20px", marginBottom: "50px" }}
        >
          <div className="l-two-col --reverse-desktop">
            <div className="l-two-col__col">
              <div
                style={{
                  position: "relative",
                  textAlign: "right",
                  height: "386px",
                  maxWidth: "482px",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    overflow: "hidden",
                    background: "none !important",
                    height: "386px",
                    maxWidth: "482px",
                    width: "100%",
                  }}
                >
                  <iframe
                    height="386"
                    style={{ maxWidth: "482px", width: "100%" }}
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3886.974082265303!2d77.55312281482271!3d13.03732169081283!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae3db65e5ad689%3A0x7cfda4bae1bf5808!2sJobCards%20India!5e0!3m2!1sen!2sin!4v1597846925796!5m2!1sen!2sin"
                    frameborder="0"
                    scrolling="no"
                    marginheight="0"
                    marginwidth="0"
                  ></iframe>
                </div>
              </div>
            </div>
            <div className="l-two-col__col">
              <div className="text-lockup">
                <h2 className="text-lockup__heading js-underline-container js-toggle-on-scroll --is-in-view">
                  Job Cards
                </h2>
                <div className="text-lockup__body">
                  <p>
                    #6/17A, 6th main, Nanjamari Layout, Sharadamba Nagar,
                    Jalahalli,{" "}
                    <span style={{ whiteSpace: "nowrap" }}>
                      Bangalore - 560013
                    </span>
                  </p>
                  <p>Phone: +91-9916750126 / 9844374106</p>
                  {/* <p>For quotes - <a href="mailto:enquiry@jobcards.in">enquiry@jobcards.in</a><br />
                                    Registration / contact us -  */}
                  <a href="mailto:contact@jobcards.in">contact@jobcards.in</a>
                  {/* </p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

export default ContactPage;
