import React from "react";
import { NavLink } from "react-router-dom";
class Footer extends React.Component {
  render() {
    return (
      <div className="site-footer">
        <div className="site-footer__inner">
          <div className="site-footer__top">
            <a
              href="https://jobcards.in/"
              className=""
              style={{ color: "aliceblue", fontSize: "25px" }}
            >
              <img
                src="/images/jobcards-white.png"
                style={{ width: "200px" }}
                alt=""
              />
            </a>
          </div>
          <nav className="site-footer__links">
            <div className="site-footer__links-section --wide">
              <a href="https://jobcards.in/" className="site-footer__blog-link">
                <span className="quote__author f-16">
                  Founded in 2020, JobCards network has since processed more
                  than 1000+ requests, making JobCards the trusted partner in
                  manufacturing space. Our company headquarters is located in
                  the vibrant city Bangalore, Karnataka.
                </span>
              </a>
            </div>
            <div className="site-footer__links-section">
              <h6 className="body-xs u-color-periwinkle site-footer__links-section-header">
                Categories
              </h6>
              <ul className="site-footer__link-list">
                <li className="site-footer__link">
                  <NavLink to="/">Suppliers</NavLink>
                </li>
                <li className="site-footer__link">
                  <NavLink to="/">Manufacturers</NavLink>
                </li>
                {/* <li className="site-footer__link">
                                    <NavLink to="/">Testing</NavLink>
                                </li>
                                <li className="site-footer__link">
                                    <NavLink to="/">Certification</NavLink>
                                </li> */}
              </ul>
            </div>
            <div className="site-footer__links-section">
              <h6 className="body-xs u-color-periwinkle site-footer__links-section-header">
                Company
              </h6>
              <ul className="site-footer__link-list">
                <li className="site-footer__link">
                  <a href="https://jobcards.in/about.html">About Us</a>
                </li>
                <li className="site-footer__link">
                  <a href="https://jobcards.in/how-it-works.html">
                    How it works
                  </a>
                </li>
                <li className="site-footer__link">
                  <NavLink to="/">Find Businesses</NavLink>
                </li>
                <li className="site-footer__link">
                  <NavLink to="/msme-list">MSME</NavLink>
                </li>
                <li className="site-footer__link">
                  <NavLink to="/contact">Contact us</NavLink>
                </li>
              </ul>
            </div>
          </nav>
          <div className="site-footer__blog-and-social">
            <div className="site-footer__blog">
              <h6 className="body-xs u-color-periwinkle site-footer__links-section-header">
                Company Address
              </h6>
              <NavLink to="contact" className="site-footer__blog-link">
                <span className="quote__author f-16">
                  #6/17A, 6th main, Nanjamari Layout, Sharadamba Nagar,
                  Jalahalli,{" "}
                  <span style={{ whiteSpace: "nowrap" }}>
                    Bangalore - 560013
                  </span>
                </span>
              </NavLink>
              <span className="quote__author f-16">
                <br />
                Phone: +91-9916750126 / 9844374106
                <br />
              </span>
              <span class="quote__author f-16">
                Email -{" "}
                <a href="mailto:contact@jobcards.in">contact@jobcards.in</a>
              </span>
            </div>
            <div className="site-footer__social">
              <h6 className="body-xs u-color-periwinkle">Follow</h6>
              <div className="icon-list --fill-white-hover-periwinkle --social">
                <a
                  href="https://jobcards.in/"
                  className="icon-list__item"
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                >
                  <svg
                    width="22"
                    height="22"
                    viewBox="0 0 22 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M20.7059 0H1.29412C0.554622 0 0 0.554622 0 1.29412V20.8908C0 21.6303 0.554622 22.1849 1.29412 22.1849H11.8319V13.4958H8.87395V10.1681H11.6471V7.76471C11.6471 4.9916 13.3109 3.32773 15.8992 3.32773C17.1933 3.32773 18.1176 3.32773 18.4874 3.51261V6.47059H16.6387C15.3445 6.47059 14.9748 7.21008 14.9748 8.13445V10.1681H18.4874L18.1176 13.4958H15.1597V22H20.7059C21.4454 22 22 21.4454 22 20.7059V1.29412C22 0.554622 21.4454 0 20.7059 0Z"
                      fill="#FFFFFF"
                    />
                    <defs>
                      <rect width="22" height="22" fill="#FFFFFF" />
                    </defs>
                  </svg>
                </a>
                <a
                  href="https://jobcards.in/"
                  className="icon-list__item"
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                >
                  <svg
                    viewBox="0 0 22 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M19.8 4.43077C19.8 4.56923 19.8 4.84615 19.8 4.98462C19.8 11.0769 15.2625 18 6.875 18C4.2625 18 1.925 17.3077 0 15.9231C0.4125 15.9231 0.6875 15.9231 1.1 15.9231C3.1625 15.9231 5.225 15.2308 6.7375 13.9846C4.8125 13.9846 3.1625 12.6 2.475 10.8C2.75 10.8 3.025 10.9385 3.3 10.9385C3.7125 10.9385 4.125 10.9385 4.5375 10.8C2.475 10.3846 0.825 8.58462 0.825 6.36923C1.5125 6.64615 2.2 6.92308 2.8875 6.92308C1.65 6.09231 0.9625 4.70769 0.9625 3.04615C0.9625 2.21538 1.2375 1.38462 1.5125 0.692308C3.7125 3.46154 7.0125 5.26154 10.8625 5.4C10.725 5.26154 10.725 4.84615 10.725 4.56923C10.725 2.07692 12.7875 0 15.2625 0C16.5 0 17.7375 0.553846 18.5625 1.38462C19.525 1.24615 20.625 0.830769 21.45 0.276923C21.175 1.38462 20.35 2.21538 19.525 2.76923C20.35 2.76923 21.175 2.49231 22 2.07692C21.45 3.04615 20.625 3.87692 19.8 4.43077Z"
                      fill="#FFFFFF"
                    />
                  </svg>
                </a>
                <a
                  href="https://jobcards.in/"
                  className="icon-list__item"
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                >
                  <svg
                    width="22"
                    height="22"
                    viewBox="0 0 22 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.9291 14.6194C12.9672 14.6194 14.6194 12.9672 14.6194 10.9291C14.6194 8.89098 12.9672 7.23877 10.9291 7.23877C8.89098 7.23877 7.23877 8.89098 7.23877 10.9291C7.23877 12.9672 8.89098 14.6194 10.9291 14.6194Z"
                      fill="#FFFEFE"
                    />
                    <path
                      d="M22 6.52903C22 5.39355 21.7161 4.54194 21.4323 3.83226C21.1484 3.12258 20.7226 2.55484 20.1548 1.84516C19.5871 1.27742 18.8774 0.851613 18.1677 0.567742C17.4581 0.283871 16.6065 0.141935 15.471 0C14.3355 0 13.9097 0 10.929 0C7.94839 0 7.66452 0 6.52903 0C5.39355 0 4.54194 0.283871 3.83226 0.567742C3.12258 0.851613 2.4129 1.27742 1.84516 1.84516C1.27742 2.4129 0.851613 3.12258 0.567742 3.83226C0.283871 4.54194 0.141935 5.25161 0 6.52903C0 7.66452 0 8.09032 0 11.071C0 14.0516 0 14.4774 0 15.6129C0 16.7484 0.283871 17.6 0.567742 18.3097C0.851613 19.0194 1.27742 19.5871 1.84516 20.2968C2.4129 20.8645 3.12258 21.2903 3.83226 21.5742C4.54194 21.8581 5.39355 22 6.52903 22.1419C7.66452 22.1419 8.09032 22.1419 11.071 22.1419C14.0516 22.1419 14.4774 22.1419 15.6129 22.1419C16.7484 22.1419 17.6 21.8581 18.3097 21.5742C19.0194 21.2903 19.5871 20.8645 20.2968 20.2968C20.8645 19.729 21.2903 19.0194 21.5742 18.3097C21.8581 17.6 22 16.7484 22.1419 15.6129C22.1419 14.4774 22.1419 14.0516 22.1419 11.071C22.1419 8.09032 22 7.66452 22 6.52903ZM10.929 16.6065C7.80645 16.6065 5.25161 14.0516 5.25161 10.929C5.25161 7.80645 7.80645 5.25161 10.929 5.25161C14.0516 5.25161 16.6065 7.80645 16.6065 10.929C16.6065 14.0516 14.0516 16.6065 10.929 16.6065ZM16.8903 6.3871C16.1806 6.3871 15.6129 5.81935 15.6129 5.10968C15.6129 4.4 16.1806 3.83226 16.8903 3.83226C17.6 3.83226 18.1677 4.4 18.1677 5.10968C18.1677 5.81935 17.6 6.3871 16.8903 6.3871Z"
                      fill="#FFFFFF"
                    />
                    <defs>
                      <rect width="22" height="22" fill="white" />
                    </defs>
                  </svg>
                </a>
                <a
                  href="https://jobcards.in/"
                  className="icon-list__item"
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                >
                  <svg
                    width="21"
                    height="20"
                    viewBox="0 0 21 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.8125 8.49658C11.8125 8.36585 11.6812 8.49658 11.8125 8.49658V8.49658Z"
                      fill="#FFFEFE"
                    />
                    <path
                      d="M4.72495 6.53613H0.262451V20.0002H4.72495V6.53613Z"
                      fill="#FFFEFE"
                    />
                    <path
                      d="M2.49375 4.70588C0.91875 4.70588 0 3.66013 0 2.35294C0 1.04575 1.05 0 2.49375 0C4.06875 0 4.9875 1.04575 4.9875 2.35294C5.11875 3.66013 4.06875 4.70588 2.49375 4.70588Z"
                      fill="#FFFEFE"
                    />
                    <path
                      d="M21 19.9999H16.5375V12.8104C16.5375 10.9803 15.8812 9.80383 14.3062 9.80383C13.125 9.80383 12.3375 10.5881 11.9438 11.5032C11.8125 11.6339 11.8125 12.026 11.8125 12.4182V19.9999H7.21875C7.21875 19.9999 7.21875 7.84304 7.21875 6.53585H11.8125V8.49664C12.4688 7.5816 13.5188 6.27441 15.8813 6.27441C18.9 6.27441 21 8.2352 21 12.2875V19.9999Z"
                      fill="#FFFEFE"
                    />
                  </svg>
                </a>
              </div>
            </div>
          </div>

          <div className="site-footer__copyright">
            <p className="body-xs u-color-white">
              © 2020 JobCards All&nbsp;Rights&nbsp;Reserved.
            </p>
          </div>
        </div>
      </div>
    );
  }
}
export default Footer;
