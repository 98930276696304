import React from 'react';
import { NavLink } from 'react-router-dom';
class BusinessCard extends React.Component {
    render() {
        const { post, selectedItems, selectBusiness } = this.props;
        return (
            <div className="business-card">
                <NavLink to={`/business-details/${post.id}`}>  
                    {/* <div className="business-card-image" style={{ backgroundImage: (post.logo) ? `url(https://backend.jobcards.in${post.logo.url})` :"url('/images/1.png')" }}></div> */}
                    <div className="business-card-image" style={{ backgroundImage: (post.logo) ? `url(${post.logo.url})` :"url('/images/1.png')" }}></div>

                </NavLink>
                <div className="check-c">
                    <label className="checkbox-container">
                        <input type="checkbox" value={post.id} id={post.id} name={post.id} onChange={e => selectBusiness(e.target.value)} checked={(selectedItems.indexOf(post.id)) === -1 ? false : true} />
                        <span className="checkmark"></span>
                    </label>
                </div>
                <NavLink to={`/business-details/${post.id}`}>
                    <h5 className="business-card-heading">{post.name}</h5>
                    <p className="business-card-location">{post.address ? post.address.address_line_1 + "," + post.address.city?.name : "-"}  </p>
                </NavLink>
            </div>
        )
    }
}
export default BusinessCard