// routes page
// import PageNotFoundPage from './_components/page-not-found';
import { MainPage, BusinessDetails } from './_components/pages/index';
import BusinessSubmit from './_components/pages/BusinessSubmit';
import BusinessSuccess from './_components/pages/BusinessSuccess';
import ContactPage from './_components/pages/ContactPage';
import MsmePages from './_components/pages/MSMEPages';
import MSMEDetails from './_components/pages/MSMEDetails';

const routes = [
  { path: '/', exact: true, name: 'Home', component: MainPage },
  { path: '/business-details/:Id', name: 'BusinessDetails', component: BusinessDetails },
  { path: '/business-submit', name: 'BusinessSubmit', component: BusinessSubmit },
  { path: '/business-success', name: 'BusinessSuccess', component: BusinessSuccess },
  { path: '/msme-list', name: 'MsmePages', component: MsmePages },
  { path: '/msme-details/:Id', name: 'MSMEDetails', component: MSMEDetails },
  { path: '/contact', name: 'ContactPage', component: ContactPage },
  //   { path: '**', exact: true, name: '', component: PageNotFoundPage }
];

export default routes;