import React from 'react';
import { NavLink } from 'react-router-dom';
class BusinessSuccess extends React.Component {
    render() {
        return (
            <main id="main-content" tabIndex="-1" role="main">
                <div className="hero --no-padding-bottom">
                    <div className="hero__inner">
                        <div className="success-image">
                            <img src="/images/submitted.png" alt="" />
                        </div>
                        <h1 className="hero__heading heading-xl u-text-align-center js-underline-container js-toggle-on-scroll --is-in-view custom-page-heading thank-msg">
                            Thank You<br />Your Request has been sent
                        </h1>
                        <div className="go-home-c">
                            <NavLink to="/">
                                <button className="btn btn-go-home">Go to Homepage</button>
                            </NavLink>
                        </div>
                    </div>
                </div>
            </main>
        )
    }
}
export default BusinessSuccess