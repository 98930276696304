import React from 'react';
import { NavLink } from 'react-router-dom';
class Header extends React.Component {
    state = {
        menuIsOpen: false,
    }
    enableMenu() {
        this.setState({
            menuIsOpen: !this.state.menuIsOpen
        })
    }
    disableMenu() {
        this.setState({
            menuIsOpen: false
        })
    }
    render() {
        return (
            // <div className="site-header js-site-header --has-alert --is-toggled">
            <div className={"site-header js-site-header --has-alert " + (this.state.menuIsOpen ? "--is-toggled" : "")}>
                <div className="site-header__inner">
                    <a href="https://jobcards.in/" className="site-header__logo">
                        <h1 style={{ fontSize: "25px" }}><img style={{ maxWidth: "none", width: "175px" }} src="/images/jobcards.png" alt="Jobcards" /></h1>
                    </a>
                    <div className="site-header__nav">
                        {/* <nav className="nav-primary js-mobile-nav --is-toogled"> */}
                        <nav className={"nav-primary js-mobile-nav " + (this.state.menuIsOpen ? "--is-toggled" : "")}>
                            <div className="nav-primary__main">
                                <ul className="nav-primary__links">
                                    <li className="nav-primary__link">
                                        <a href="https://jobcards.in/about.html">About Us</a>
                                    </li>
                                    <li className="nav-primary__link">
                                        <a href="https://jobcards.in/how-it-works.html">How it works</a>
                                    </li>
                                    <li className="nav-primary__link">
                                        <NavLink onClick={() => this.disableMenu()} to="/">Find Businesses</NavLink>
                                    </li>
                                    <li className="nav-primary__link">
                                        <NavLink onClick={() => this.disableMenu()} to="/msme-list">News</NavLink>
                                    </li>
                                    <li className="nav-primary__link">
                                        <a href="/contact">Register with us</a>
                                    </li>
                                </ul>
                            </div>
                            <div className="nav-primary__toggle js-mobile-nav-toggle" onClick={() => this.enableMenu()}><span></span></div>
                        </nav>
                    </div>
                </div>
            </div>
        )
    }
}
export default Header